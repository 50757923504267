import React from "react";

const SuccessStory = () => {
    return (
        <>
            <section className='repeat-section our-success-section'>
				<div className='container'>
					<div className='success-bg'>
						<div className='section-title max-width-unset'>
							<div className='subtitle text-red'>Achieving Success with Vimerse InfoTech Inc</div>
							<h2 className='text-white'>Strategies and Best Practices for Building a Thriving Tech Business</h2>
						</div>
						<div className='row'>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>180+</h3>
									<p>Projects Launched </p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>5+</h3>
									<p>Years of Experience</p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>75+</h3>
									<p>Dedicated Developers</p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>90%</h3>
									<p>Client Retention</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        </>
    );
}

export default SuccessStory