import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import { Helmet } from 'react-helmet';

const Industries = () => {
	return (
		<>
			<Helmet>
				<title>Empowering Industries With Tailored Solutions | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc helps key industries design and develop their desired solutions, such as software, an app or a website, and consultancy for all needs." />
				<link rel="canonical" href="https://vimerseit.com/industries" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Empowering Industries with Tailored Solutions</h1>
								</div>
								<p>Get Solutions for all your industry needs, from idea formulation to launch!</p>
								<p>
									Vimerse InfoTech Inc is here to assist you with all consulting and services needs. With us, you can have a mobile app, website or software to help you achieve your precise business goals. Our solutions and services cover almost all industries. We provide affordable solutions to businesses around the globe.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Modernize Your Business!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/information-technology.jpg" alt='Empowering Industries with Tailored Solutions' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Impacting Every Industry</h2>
					</div>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<h3>Our solutions are crafted to fit your business perfectly, ultimately lowering the risks and costs while holding your data compliant, secure, and accessible.</h3>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
						</div>
						<div className='col-lg-6'>
							<div className='about-industry-image'>
								<div className='row justify-content-center'>
									<div className='col-md-4 col-6'></div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'></div>
									<div className='col-md-4 col-6'>
										<div className='vimerse-icon'>
											<img src='../images/partners-logo/amazon-web-logo.svg' />
										</div>
									</div>
									<div className='col-md-4 col-6'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<section className='repeat-section industry-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Explore Our Industry Expertise</h2>
						<p>Here are some glimpses of our industry expertise, so explore and find out how we can help you with your suitable needs. </p>
					</div>
					<div className='row'>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/government-industry.jpg' alt="Government" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Government</h3>
									<p>We are extensively experienced in providing technical assistance to the governing bodies that allow us to design, develop, and deploy solutions to the citizens. Vimerse InfoTech can assist you with high transparency and effective citizen services. </p>
									<div className='readmore-btn'>
										<Link to="/industries/goverment" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/information-technology.jpg' alt='Information Technology' className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Information Technology </h3>
									<p>Information Technology is an integral part of any business as it offers multiple advantages to leverage for growth. We can provide you with the right team for it to implement the latest IT solutions. </p>
									<div className='readmore-btn'>
										<Link to="/information-technology" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div> */}
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/engineering-manufacturing.jpg' alt="Engineering Manufacturing" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Engineering Manufacturing</h3>
									<p>The manufacturing industry can overcome several challenges with the help of technology and solutions. If you are involved in manufacturing, we can help you streamline all your ops via providing domain based IT solutions.</p>
									<div className='readmore-btn'>
										<Link to="/industries/engineering-manufacturing" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/banking-and-finance.jpg' alt="Banking & Finance" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Banking & Finance</h3>
									<p>Banking and finance is one of the emerging sectors where innovation is still needed. Our industry specialists can provide solutions that offer better customer experience and service quality, including following compliance. </p>
									<div className='readmore-btn'>
										<Link to="/industries/banking-and-finance" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/retail-and-ecommerce.jpg' alt="Retail & eCommerce" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Retail & eCommerce</h3>
									<p>Retail & ecommerce tend to cater to the demand of a large customer base. So for the best shopping experience and flawless services, a solution can help a lot. We have expertise in it, and our experts can develop it based on all your needs.</p>
									<div className='readmore-btn'>
										<Link to="/industries/retail-and-ecommerce" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/healthcare.jpg' alt="Healthcare" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Healthcare</h3>
									<p>Patient-centric solutions, seamless workflows, and data privacy are the key requirements of the current healthcare industry. With us, you will have a seamless experience with modern healthcare solutions covering all needs. </p>
									<div className='readmore-btn'>
										<Link to="/industries/healthcare-development" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/telecommunications.jpg' alt="Telecommunications" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Telecommunications</h3>
									<p>Create a personalized telecommunication experience via accelerating network automation and user-oriented IT solutions. Our experts have rooted knowledge in implementing result-oriented telecommunication solutions. </p>
									<div className='readmore-btn'>
										<Link to="/industries/telecommunications" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/education-eLearning.jpg' alt="Education & eLearning" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Education & eLearning</h3>
									<p>Education & eLearning is going from a revolutionary phase, and there is ample need for exceptional solutions. We can help you to make the teaching-learning process innovative with our IT expertise, where you can easily map outcomes. </p>
									<div className='readmore-btn'>
										<Link to="/industries/education-and-elearning" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/travel-hospitality.jpg' alt="Travel & Hospitality" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Travel & Hospitality</h3>
									<p>Give your customers the best experience of your travel services with innovative solutions. Vimerse InfoTech Inc is a leading services and solutions provider to the travel industry, where you will have some key advantages such as cost-effectiveness and scalability.</p>
									<div className='readmore-btn'>
										<Link to="/industries/travel-and-hospitality" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/food-restaurants.jpg' alt="Food & Restaurants" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Food & Restaurants</h3>
									<p>Lorem ipsum</p>
									<div className='readmore-btn'>
										<Link to="/industries/food-and-restaurants" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div> */}
						<div className='col-xl-4 col-lg-4 col-md-6 d-flex'>
							<div className='industry-bx'>
								<div className='image'>
									<img src='../images/industry/media-entertainment.jpg' alt="Media & Entertainment" className='img-fluid' />
								</div>
								<div className='industry-detail'>
									<h3>Media & Entertainment</h3>
									<p>Media & entertainment industry is one of the dynamic industries where content creation, management, and distribution are its key tasks. We can provide you with a team of experts to ease your media business tasks.</p>
									<div className='readmore-btn'>
										<Link to="/industries/media-and-entertainment" className='default-btn btn-primary'>Read More <span></span></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default Industries
