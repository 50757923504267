import React, { useEffect } from 'react';
import ContactForm from '../ContactForm/ContactFormFooter';
const BlogSidebar = () => {
    useEffect(() => {
        const handleScroll = () => {
            const stickyDiv = document.getElementById('stickyDiv');
            const scrollY = window.scrollY || window.pageYOffset;
            const topOffset = 100;

            if (scrollY >= topOffset) {
                stickyDiv.classList.add('sticky');
            } else {
                stickyDiv.classList.remove('sticky');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <div className='blog-sidebar'>
                <div className='sticky-element' id="stickyDiv">
                    <div className='discuss-project-form'>
                        <h3>Discuss your project</h3>
                        <p>
                            Vimerse InfoTech is a professional software development service company.
                        </p>

                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogSidebar;