import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import { Helmet } from 'react-helmet';

const AmazonWebServices = () => {
	return (
		<>
			<Helmet>
				<title>Amazon Web Service | Vimerse InfoTech</title>
				<meta name="description" content="Unleash the power of business with Amazon Web Services. Vimerse InfoTech offers AWS Cloud services including cost effectiveness, scalability & flexibility." />
				<link rel="canonical" href="https://vimerseit.com/amazon-web-services" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Amazon Web Service</h1>
								</div>
								<p>
									Make the most out of the AWS Cloud and accelerate your business growth at a reduced cost.
								</p>
								<p>
									Being a leading cloud services provider to businesses, Vimerse InfoTech Inc offers you personalized AWS web services that suit your needs. We can provide you with resources and a team of extensively skilled Cloud engineers who can assist you in meeting your objectives. Use Amazon web services with the assistance of industry professionals to maximize your efficiency.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Amplify Your Business With AWS!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/aws-banner.jpg" alt="Amazon Web Service" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff '>
				<div className='container'>
					<div className='section-title'>
						<h2>Range Of Cloud Services to Make Business Thrive</h2>
						<p>Leverage Vimerse InfoTech Inc’s expertise for your business and choose from a wide range of services powered by Amazon Web Services. Including cloud practices in business is a good investment, and we will make it worth it for you.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="AWS Consulting" width="60px" />
								</div>
								<h3>AWS Consulting</h3>
								<p>Get assistance from industry experts with a proven track record in providing the best-in-class AWS consulting from implementation to management.  They will help you to Assess your existing infra to find compatibility with AWS.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/implementation.svg' alt="AWS Implementation" width="60px" />
								</div>
								<h3>AWS Implementation</h3>
								<p>Implement AWS infrastructure meeting all your business requirements and make the most out of Amazon Web Services with the help of experts. We can help you to implement AWS with personalization.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/integration2.svg' alt="AWS Integration" width="60px" />
								</div>
								<h3>AWS Integration</h3>
								<p>Integrate AWS with your existing system and unlock the full potential of your business. We will assist in doing it seamlessly with reduced cost, as we have done for multiple clients. Get it the best with us.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/app-development.svg' alt="Application Development" width="60px" />
								</div>
								<h3>Application Development</h3>
								<p>AWS app development is beneficial in all terms for businesses who are looking forward to growing with Cloud technologies. We have an experienced team of app developers who assist the best.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/migration2.svg' alt="AWS Cloud Migration" width="60px" />
								</div>
								<h3>AWS Cloud Migration</h3>
								<p>Want to migrate from existing cloud services to Amazon Web Services? Get the best experience of AWS cloud migration services from Vimerse InfoTech Inc. We deliver high-quality services to all businesses.   </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/security.svg' alt="Security Management" width="60px" />
								</div>
								<h3>Security Management</h3>
								<p>Leverage our AWS expertise for security management and maintenance. Our team of AWS experts provides services to monitor the system, identify the risk, and implement security mechanisms to secure Cloud infra. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f2f2f2'>
				<div className='container'>
					<div className='section-title'>
						<h2>Why Vimerse InfoTech Inc For AWS?</h2>
						<p>It is our dedicationṣ and expertise that makes us a better option for AWS Cloud services; with us, you will have advantages like:</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/customer-rating.svg' alt="Experience" width="60px" />
								</div>
								<h3>Experience</h3>
								<p>We have the experience to deliver AWS solutions for diverse businesses, organizations, and industries.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/finance.svg' alt="Affordability" width="60px" />
								</div>
								<h3>Affordability</h3>
								<p>Our service ecosystem is designed to be available for all-size businesses. We offer a variety of services that you can leverage at affordable cost.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/flexibility.svg' alt="Flexibility" width="60px" />
								</div>
								<h3>Flexibility</h3>
								<p>Whatever your need, we can assist you in including AWS Cloud in your business to streamline operation practices.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/customer-rating.svg' alt="Customer Satisfaction" width="60px" />
								</div>
								<h3>Customer Satisfaction</h3>
								<p>Customer satisfaction is always our priority. We used to provide tailored solutions that an enterprise utilizes the best for specific needs.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/scalability.svg' alt="Scalability" width="60px" />
								</div>
								<h3>Scalability</h3>
								<p>We provide scalable solutions that you can leverage for expanding your business anytime or whenever you need them.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/growth.svg' alt="Proven Track Record" width="60px" />
								</div>
								<h3>Proven Track Record</h3>
								<p>Vimerse InfoTech Inc has a proven track record in implementing AWS Cloud Platform for diverse industries.</p>
							</div>
						</div>
					</div>
				</div>
			</section>


			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default AmazonWebServices
