import React from 'react';
import BlogPagePostList from '../../components/BlogData/BlogPagePostList';
// import BlogPost from '../../components/BlogData/BlogPost';
// import Posts from '../../components/BlogList/BlogData';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
    return (
        <>
            <Helmet>
                <title>Vimerse InfoTech's Latest News, Feeds, and Technology Updates</title>
                <meta name="description" content="Stay up-to-date with Vimerse InfoTech's latest news, feeds, and technology updates in mobile and web development. Discover valuable insights, trends, and advancements to stay ahead in the ever-evolving world of technology. Don't miss out on our informative blog posts and recent updates that can inspire and guide you." />
                <link rel="canonical" href="https://vimerseit.com/blog" />
            </Helmet>

            <section className="blog-area repeat-section">
                <div className="container">
                    <div className="section-title">
                        <h1>Tech Insights: Empowering Your Digital Future</h1>
                        <p>Exploring the Latest Innovations, Trends, and Solutions in the IT World.</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="row">
                                <BlogPagePostList />
                                {/* <BlogPostList />
                                <BlogPostList /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogPage
