import React from 'react';
import Header from '../views/components/Header/Header'


export default  function TheHeader () {
	return (
		<>
			<Header />
		</>
	);
};