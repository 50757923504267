import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';
import "./ContactForm.scss";


const ContactForm = () => {
    const [thankYouText, setThankYouText] = useState(false);
    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!').required('Your first name is required.'),
        phonenum: Yup.string().required('Your phone is required.'),
        categoerySelect: Yup.string().required('Please select option.'),
        email: Yup.string().email('Invalid email').required('Email is required.'),
        message: Yup.string().required('Your message is required.'),
    });

    const sendEmail = (values) => {
        emailjs
            .send('service_w35peus', 'template_mox25t7', values, 'oSJC6J_sLj3scVbYj')
            .then((response) => {
                setThankYouText(true);
                console.log('Email sent successfully!', response);
                // Handle success, e.g., show a success message to the user
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                // Handle error, e.g., show an error message to the user
            });
    };

    return (
        <>
            <>
                <Formik
                    initialValues={{
                        firstName: '',
                        email: '',
                        phonenum: '',
                        categoerySelect: '',
                        message: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={sendEmail}
                >
                    {({ errors, touched, field, form, values, handleBlur, handleChange }) => (
                        <Form className='form-resume-submit p-0 box-shadow-none'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        <Field name="firstName" className="form-control" placeholder="Your First Name" />
                                        <ErrorMessage name="firstName" render={msg => <div className='error'>{msg}</div>} />
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        <Field name="email" type="email" className="form-control" placeholder="Your Email" />
                                        <ErrorMessage name="email" render={msg => <div className='error'>{msg}</div>} />
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        <Field type="tel" name="phonenum" className="form-control" placeholder="Phone Number" />
                                        <ErrorMessage name="phonenum" render={msg => <div className='error'>{msg}</div>} />
                                    </div>
                                </div>

                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        <Field as="select" className="form-control custom-select" name="categoerySelect">
                                            <option value="" disabled selected>I am Interested In</option>
                                            <option value="Software Development">Software Development</option>
                                            <option value="Training">Training</option>
                                            <option value="Admin">Admin</option>
                                            <option value="Human Resource">Human Resource</option>
                                            <option value="Career">Career</option>
                                            <option value="Accounts/Finance">Accounts/Finance</option>
                                            <option value="Legal/Compliance">Legal/Compliance</option>
                                            <option value="Management">Management</option>
                                            <option value="Bussiness Enqueries">Bussiness Enqueries</option>
                                        </Field>
                                        <ErrorMessage name="categoerySelect" render={msg => <div className='error'>{msg}</div>} />
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        {/* <Field name="message" className="form-control" placeholder="Message" /> */}
                                        <Field as="textarea" name="message" className="form-control" placeholder="Your message" />
                                        <ErrorMessage name="message" render={msg => <div className='error'>{msg}</div>} />
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <button type="submit" className='default-btn'>Submit <span></span></button>
                                    {!thankYouText ? (
                                        <></>
                                    ) : (
                                        <span className='thanks-para'>Thanks for connecting with us. Concern team will contact you soon.</span>
                                    )}
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>

        </>
    );
}

export default ContactForm
