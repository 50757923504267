import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const ProjectManagement = () => {
	return (
		<>
			<Helmet>
				<title>Project Management | Vimerse InfoTech</title>
				<meta name="description" content="Maximize project efficiency and deliver successful outcomes with our professional project management services within your budget. Get it from industry experts." />
				<link rel="canonical" href="https://vimerseit.com/project-management" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Project Management</h1>
								</div>
								<p>
									Manage your projects effortlessly with the help of experienced project management experts.
								</p>
								<p>
									Vimerse InfoTech Inc offers project management consultancy and solutions that can simplify all your project management tasks. We can help you to manage your projects in no time. Our project management professionals are experienced and follow the best industry practices to create the best experience for project management services. Our PM team comprises diverse technical professionals and qualified managers with years of experience. They can help you decide the priorities, risk assessment, timeline assessment, control procedures, and more.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Amplify Your Project Management Process!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/project-management-banner.jpg" alt="Project Management" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff '>
				<div className='container'>
					<div className='section-title'>
						<h2>Project management Services - Commitment to Quality</h2>
						<p>Our proven methods make us the best option for project management.</p>
						<p>
							Vimerse InfoTech ensures clients have the best experience in project management services. We help you from start to end. We also ensure that projects must be completed under the given timeline meeting the client’s expectations. Our project management services include the following:
						</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/project-manager.svg" alt="IT Project Management" />
								</div>
								<h3>IT Project Management</h3>
								<p>Sometimes it is difficult to manage IT projects due to the involvement of resources, hardware, and software. Here we can offer you perfect IT project management services that include technical support, resource management, requirement collection, and many more.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/management.svg" alt="PMO & EPMO" />
								</div>
								<h3>PMO & EPMO </h3>
								<p>Enterprise Project Management(PMO) and Establishing Project Management Offices(EPMO) is crucial for managing the project effectively. It helps in performing key actions while managing the project. Our professionals will help you to set it perfectly.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/methodology.svg" alt='PMM Development' />
								</div>
								<h3>PMM Development</h3>
								<p>PMM stands for project management methodology and helps maintain consistency, creating a system of principles, techniques, and procedures for managing projects. Our professionals are experienced in developing and implementing PMM in the best possible way.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/consulting.svg" alt="Project Management Consultation" />
								</div>
								<h3>Project Management Consultation</h3>
								<p>Vimerse InfoTech Inc offers project management consultation services following the best industry practices. With us, you can access the extensive skill set of our professionals, who will assist you with planning, scheduling, budgeting, staff augmentation, and more.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Effortless Project Management With Benefits</h2>
						<p>Manage your projects in no time with the help of Vimerse InfoTech Inc. The benefits you will have.</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/improve.svg" alt="Improved Efficiency" />
								</div>
								<h3>Improved Efficiency</h3>
								<p>Our project management services can help you to have improved efficiency via streamlining the management process, including reduced timeline and cost.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/scope.svg" alt="Clear Project Scope" />
								</div>
								<h3>Clear Project Scope</h3>
								<p>We will assist you in having clear project scope, defining objectives, and ensuring deliverability on time. You will have a clear view of how your project will meet all your needs.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/calendar2.svg" alt="Enhanced Scheduling" />
								</div>
								<h3>Enhanced Scheduling</h3>
								<p>Scheduling is one of the critical aspects of project management, and here, our professionals will provide their support from end-to-end such as task sequencing, setting deadlines, and more.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/warning.svg" alt="Risk Management" />
								</div>
								<h3>Risk Management</h3>
								<p>Our project managers are experienced in identifying potential risks, and they can assist you in managing them in a seamless way via risk assessment, contingency planning, and risk monitoring.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/badge.svg" alt="Quality Control" />
								</div>
								<h3>Quality Control</h3>
								<p>A key advantage of Vimerse InfoTech Inc.'s project management services is quality control. We always focus on maintaining high-quality standards, including assured deliverables.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/flexibility.svg" alt="Flexibility" />
								</div>
								<h3>Flexibility</h3>
								<p>While managing the project with us, you will have super flexibility. In case of any change in requirements or project scope, we can help you to address the changes.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default ProjectManagement
