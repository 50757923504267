import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactFormFooter';
import './Footer.scss';
import GoTop from '../GoTop/GoTop';
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer className='footer-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div className='about-footer'>
                                <div className='footer-logo'>
                                    <Link href='/'>
                                        <Image src='../images/logo.png' alt='Vimerse InfoTech' width="200" className='img-fluid' />
                                    </Link>
                                </div>

                                <div className='contact-info-row'>
                                    <div className='col'>
                                        <div className='icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="80" viewBox="0 0 60 80">
                                                <path fill='Currentcolor' d="M27.042,78.886C4.339,45.974.125,42.6.125,30.5a30,30,0,0,1,60,0c0,12.1-4.214,15.474-26.917,48.386A3.752,3.752,0,0,1,27.042,78.886ZM30.125,43a12.5,12.5,0,1,0-12.5-12.5A12.5,12.5,0,0,0,30.125,43Z" transform="translate(-0.125 -0.5)" />
                                            </svg>
                                        </div>
                                        <a href='https://goo.gl/maps/gUwAgFdmo3W722ou7' target="_blank">
                                            <span>Headquarters:</span> 14200 Midway Rd, STE 122, Dallas, TX 75244
                                        </a>
                                    </div>
                                </div>
                                <div className='contact-info-row'>
                                    <div className='col'>
                                        <div className='icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 80 60">
                                                <path fill='Currentcolor' d="M78.744,19.851a.941.941,0,0,1,1.516.734V52.538a7.5,7.5,0,0,1-7.5,7.5h-65a7.5,7.5,0,0,1-7.5-7.5V20.6a.935.935,0,0,1,1.516-.734c3.5,2.719,8.141,6.172,24.078,17.75,3.3,2.406,8.859,7.469,14.406,7.437,5.578.047,11.25-5.125,14.422-7.437C70.619,26.038,75.244,22.57,78.744,19.851ZM40.259,40.038c3.625.063,8.844-4.562,11.469-6.468C72.462,18.523,74.04,17.21,78.822,13.46a3.739,3.739,0,0,0,1.437-2.953V7.538a7.5,7.5,0,0,0-7.5-7.5h-65a7.5,7.5,0,0,0-7.5,7.5v2.969A3.762,3.762,0,0,0,1.7,13.46c4.781,3.734,6.359,5.062,27.094,20.109C31.416,35.476,36.634,40.1,40.259,40.038Z" transform="translate(-0.259 -0.038)" />
                                            </svg>
                                        </div>
                                        <a href='mailto:info@vimerseit.com' target="_blank">
                                            <span>Email:</span> info@vimerseit.com
                                        </a>
                                    </div>
                                </div>
                                <div className='contact-info-row'>
                                    <div className='col'>
                                        <div className='icon'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                                                <path fill='Currentcolor' d="M77.718,56.531l-17.5-7.5a3.75,3.75,0,0,0-4.375,1.078l-7.75,9.469A57.917,57.917,0,0,1,20.407,31.892l9.469-7.75a3.741,3.741,0,0,0,1.078-4.375l-7.5-17.5a3.775,3.775,0,0,0-4.3-2.172L2.906,3.844A3.75,3.75,0,0,0,0,7.5,72.493,72.493,0,0,0,72.5,80a3.75,3.75,0,0,0,3.656-2.906l3.75-16.25a3.8,3.8,0,0,0-2.189-4.313Z" transform="translate(0 0)" />
                                            </svg>
                                        </div>
                                        <a href='tel:+1-972-214-2351' target="_blank">
                                            <span>Phone:</span> +1-972-214-2351
                                        </a>
                                    </div>
                                </div>
                                <div className='contact-info-row social-icons'>
                                    <p>Follow us On:</p>
                                    <ul>
                                        <li>
                                            <a href='https://www.facebook.com/Vimerse.InfoTech' target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.39" height="25" viewBox="0 0 13.39 25">
                                                    <path fill="Currentcolor" d="M12.577,14.817l.694-4.524H8.93V7.357a2.262,2.262,0,0,1,2.551-2.444h1.974V1.06a24.068,24.068,0,0,0-3.5-.306c-3.575,0-5.912,2.167-5.912,6.09v3.448H.064v4.524H4.039V25.755H8.93V14.817Z" transform="translate(-0.064 -0.755)" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://twitter.com/VimerseI' target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20.305" viewBox="0 0 25 20.305">
                                                    <path fill="Currentcolor" d="M22.69,5.129c.016.222.016.444.016.666A14.478,14.478,0,0,1,8.127,20.374a14.479,14.479,0,0,1-7.868-2.3,10.613,10.613,0,0,0,1.237.063,10.261,10.261,0,0,0,6.361-2.189,5.133,5.133,0,0,1-4.791-3.553,6.456,6.456,0,0,0,.968.079A5.423,5.423,0,0,0,5.383,12.3,5.125,5.125,0,0,1,1.274,7.271V7.207a5.16,5.16,0,0,0,2.316.65A5.131,5.131,0,0,1,2,1,14.564,14.564,0,0,0,12.569,6.366a5.784,5.784,0,0,1-.127-1.174,5.129,5.129,0,0,1,8.867-3.506A10.087,10.087,0,0,0,24.561.45a5.11,5.11,0,0,1-2.252,2.824,10.271,10.271,0,0,0,2.95-.793A11.016,11.016,0,0,1,22.69,5.129Z" transform="translate(-0.259 -0.069)" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://www.linkedin.com/company/vimerse-infotech-inc/' target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
                                                    <path fill="Currentcolor" d="M5.6,25H.413V8.309H5.6ZM3,6.032A3.022,3.022,0,1,1,6,3,3.027,3.027,0,0,1,3,6.032ZM24.994,25H19.823V16.874c0-1.936-.039-4.42-2.695-4.42-2.695,0-3.108,2.1-3.108,4.28V25H8.843V8.309h4.971v2.277h.073a5.446,5.446,0,0,1,4.9-2.7c5.246,0,6.21,3.454,6.21,7.941V25Z" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href='https://plus.google.com/u/0/collection/gucUYF' target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15.625" viewBox="0 0 25 15.625">
                                                    <path fill="Currentcolor" d="M15.688,6.693a6.621,6.621,0,0,1,.128,1.3c0,4.466-2.995,7.631-7.5,7.631A7.813,7.813,0,0,1,8.313,0a7.462,7.462,0,0,1,5.233,2.046L11.425,4.082a4.387,4.387,0,0,0-3.112-1.2A4.891,4.891,0,0,0,3.474,7.813a4.891,4.891,0,0,0,4.839,4.935,4.232,4.232,0,0,0,4.434-3.368H8.313V6.693h7.375Zm7.544.262V4.688H20.954V6.955H18.686V9.234h2.268V11.5h2.279V9.234H25.5V6.955Z" transform="translate(-0.5)" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className='useful-links'>
                                <h2>Useful Links</h2>
                                <ul>
                                    <li>
                                        <Link to="/about" className="nav-link">
                                            About Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/services">
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/industries">
                                            Industries
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/career">
                                            Career
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link" to="/blog">
                                            Insights
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='useful-links'>
                                <h2>Contact Form</h2>
                                <div className='contact-form-footer'>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area">
                                <div className="row">
                                    <div className="col-lg-6 order-lg-1 order-2">
                                        <p className="text-center text-lg-start">© Copyright {currentYear} vimerseit. All Rights Reserved.</p>
                                    </div>
                                    <div className="col-lg-6 order-lg-2 order-1">
                                        <div className="cr-menu-right mb-lg-0 mb-2">
                                            <ul className="list-unstyled d-flex align-items-center m-0 justify-content-lg-end justify-content-center">
                                                <li className="me-md-3 me-2">
                                                    <Link className="nav-link" to="/privacy-policy">
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li className="me-md-3 me-2">
                                                    <Link className="nav-link" to="/terms-conditions">
                                                        Terms and Conditions
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <GoTop />
        </>
    );
}


export default Footer;