import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EngineeringManufacturing = () => {
	return (
		<>
			<Helmet>
				<title>Engineering Manufacturing | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc offers technology services & solutions for the manufacturing industry. Our solutions can help you to boost productivity and mitigate potential risks." />
				<link rel="canonical" href="https://vimerseit.com/industries/engineering-manufacturing" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Engineering Manufacturing</h1>
								</div>
								<p>Innovative solutions for thriving manufacturing brands!</p>
								<p>
									The supply-chain demands are dynamically increasing, so if you are from the manufacturing industry and searching for cutting-edge solutions, partner with Vimerse InfoTech Inc. Our engineering manufacturing expertise allows us to deliver the right solutions on time. We can assist you with manufacturing consulting to deploy customized solutions based on your needs.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Upgrade Your Facility!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/engineering-manufacturing.jpg" alt='Engineering Manufacturing' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Solutions for Manufacturing Industry That Drives Values to The Businesses</h2>
						<p>Harness technology to accelerate your manufacturing process.</p>
						<p>Our services are designed to support manufacturing businesses to attain their goals and enhance their operational abilities. Our skilled professionals will help you to follow the best industry practices for the best outcomes. Here are our engineering manufacturing solutions.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/solutions3.svg' alt="Asset Management" width="60px" />
								</div>
								<h3>Asset Management</h3>
								<p>Manage all your assets with modern manufacturing software solutions powered by high-quality information technology services. We can help you manage the assets like equipment, manufacturing, and others.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/chain.svg' alt="Supply Chain Management" width="60px" />
								</div>
								<h3>Supply Chain Management</h3>
								<p>Improve your supply chain practices using innovative solutions developed by industry experts. We have a team of skilled professionals with extensive knowledge in developing technology solutions for supply chain mechanisms.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/factory.svg' alt="Smart Manufacturing" width="60px" />
								</div>
								<h3>Smart Manufacturing</h3>
								<p>Vimerse InfoTech Inc can help you to develop technology-based smart manufacturing solutions to boost productivity and increase competitiveness. We can provide solutions based on the latest technologies like AI, Data Analytics, etc.</p> 
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/inventory-management.svg' alt="Inventory Management" width="60px" />
								</div>
								<h3>Inventory Management</h3>
								<p>With the help of specialists, you may overcome any inventory and order management issues and make educated decisions about your next buy. We can assist you in smoothly implementing an enterprise-wide system.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/adaptability.svg' alt="Automation & AI" width="60px" />
								</div>
								<h3>Automation & AI</h3>
								<p>Automate your production system and achieve desired results. Our expertise in automation for the manufacturing industry allows us to design, develop, and deploy solutions that help manufacturers to improve their operations quickly.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/product-development.svg' alt="Product Platform Engneering" width="60px" />
								</div>
								<h3>Product Platform Engneering</h3>
								<p>Turn your product platform engineering idea into a real-time solution. We can help you to bring your product and services idea to life where you can expect stable growth in the market. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Get future-ready solutions for your manufacturing business and make all your business dreams come true.</h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Transform Your Business Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Benefits of Our Engineering Manufacturing Services</h2>
						<p>We aim to help manufacturers by providing services that can make their tasks easy. With Vimerse InfoTech Inc, you will have some key benefits such as:</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Improved Productivity</h3>
								<p>We can provide you with the right resources and solutions that help improve product quality and productivity. Using our solutions, you can remove all roadblocks to your manufacturing operations.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Affordability</h3>
								<p>Our team of experts will assist in reducing manufacturing costs by designing strategies and solutions that suit your needs. They will help you to streamline your existing process at the best level.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Flexibility</h3>
								<p>Be more flexible in your manufacturing operations and adapt to the changes smoothly with the EMS(engineering manufacturing solutions) by Vimerse InfoTech Inc. Our expertise can help you become more flexible with business.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Risk Mitigation</h3>
								<p>Our engineering manufacturing solutions can help you to mitigate the risks like supply chain disruptions, quality risks, and many more. It also helps in following compliance regulations and the best industry standards.</p>
							</div>
						</div>

					</div>
				</div>
			</section>

			{/* <Portfollio customClass="bg-f3f7ff" /> */}
		</>
	);
}

export default EngineeringManufacturing
