import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const MobileAppDevelopment = () => {
	return (
		<>
			<Helmet>
				<title>Mobile App Development | Vimerse InfoTech</title>
				<meta name="description" content="We offer mobile app development services that suit all business needs. You can hire dedicated mobile app developers to create trendy mobile apps with us." />
				<link rel="canonical" href="https://vimerseit.com/mobile-app-development" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Mobile App Development</h1>
								</div>
								<p>Unlock the full potential of your business with our mobile app development services. </p>
								<p>
									Our experienced developers will create a custom app tailored to your unique needs and help you from ideation to development. We ensure you a user-centric app with stunning design and a seamless backend that you can leverage to create the best experience. Our mobile app solutions remain secure and data protected so you can trust us.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Start With It Now!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/mobile-app/mobile-app-development-banner.png" alt="Mobile App Development" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff '>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Custom Mobile App Development Services</h2>
						<p>We believe in providing solutions to make a business thrive. Vimerse InfoTech Inc provides full-cycle mobile app development services to diverse businesses. Our services are available for all, from startups to enterprises.</p>
						<p>Here is our mobile app development services range that allows us to deliver streamlined solutions.</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/consulting.svg" alt="Mobile App Consultation" />
								</div>
								<h3>Mobile App Consultation</h3>
								<p>If you have an idea for a professional mobile app and want to know how to implement it, what kind of platform you can use, what kind of tech stack will be the best, and more. We will assist you in finding out all the answers.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-development.svg" alt="Android App Development" />
								</div>
								<h3>Android App Development </h3>
								<p>Android is one of the most popular mobile OS. The majority of mobile phone owners and manufacturers use it. Our Android app development services can help you to expand your customer base. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-development2.svg" alt='iOS App Development' />
								</div>
								<h3>iOS App Development</h3>
								<p>Develop mobile apps that work seamlessly across all iOS-oriented devices such as iPad, Apple Phones, and others. Our experienced team of iOS app developers will dedicatedly assist you with that. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-development3.svg" alt="Progressive Web App Development" />
								</div>
								<h3>Progressive Web App Development</h3>
								<p>PWA, aka Progressive Web Apps, is the best option if you need a cost-effective solution. These apps can run on any web browser, so customers will find it easy to use anywhere - anytime.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-development4.svg" alt='Hybrid App Development' />
								</div>
								<h3>Hybrid App Development</h3>
								<p>Get the best blend of native and web app technologies via our Hybrid app development services. Using a single code base, we can create an affordable mobile that seamlessly runs across all platforms. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/design.svg" alt='Mobile App Design' />
								</div>
								<h3>Mobile App Design</h3>
								<p>Mobile app design is crucial to create the best and most seamless user experience, and we take care of it maximum while developing the app. With us, you will have state-of-the-art mobile app designs. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-integration.svg" alt='Mobile App Integration' />
								</div>
								<h3>Mobile App Integration</h3>
								<p>Want to extend the functionality of your existing mobile app? We can help with our comprehensive experience in integrating third-party APIs and software. Our experts ensure it in less time.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/testing.svg" alt='Quality Analysis' />
								</div>
								<h3>Quality Analysis</h3>
								<p>We have a team of experienced quality analysts who can test your app until its bottom to find the last bug. Our testing services include functional testing, user testing, performance testing, and more.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Out-Of-The-Box Mobile App Solutions For Industries</h2>
						<p>Our dedicated mobile app developers have expertise in providing solutions for multiple industries with a proven track record. Having extensive experience, we have helped multiple businesses, from startups to enterprises.</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/goverment.svg" alt="Government" />
								</div>
								<h3>Government</h3>
								<p>We can build mobile apps that can ease the task of authorities.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/ecommerce-development.svg" alt="Retails & ecommerce" />
								</div>
								<h3>Retails & ecommerce</h3>
								<p>Transform your business online with an outstanding ecommerce app.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/eLearning-app.svg" alt="Education" />
								</div>
								<h3>Education</h3>
								<p>We develop ed-tech apps for modern education and according to its curriculum.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/healthcare.svg" alt="Healthcare" />
								</div>
								<h3>Healthcare</h3>
								<p>Excel in your healthcare business with new-gen healthcare apps.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/hr.svg" alt="HR App Solutions" />
								</div>
								<h3>HR App Solutions</h3>
								<p>Create a seamless experience of recruitment with streamlined HR app solutions.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/finance.svg" alt="Fintech" />
								</div>
								<h3>Fintech</h3>
								<p>Get a robust and secure mobile app for your fintech business from experts.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/telecommunication.svg" alt="Telecommunication" />
								</div>
								<h3>Telecommunication</h3>
								<p>Connect this world in no time using the latest tech-based telecommunication solutions. </p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/entertainment-media.svg" alt="Media & Entertainment" />
								</div>
								<h3>Media & Entertainment</h3>
								<p>Grow your media & entertainment business with the latest entertainment app solutions.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />


			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default MobileAppDevelopment
