import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const SoftwareDevelopment = () => {
	return (
		<>
			<Helmet>
				<title>Software Development | Vimerse InfoTech</title>
				<meta name="description" content="Transform your business with software development services by Vimerse InfoTech Inc. We create out-of-the-box software solutions for global businesses. " />
				<link rel="canonical" href="https://vimerseit.com/software-development" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Software Development</h1>
								</div>
								<p>
									Digitize your business with the latest tech-based software development services.
								</p>
								<p>
									Technology is rapidly progressing, and businesses are leveraging it in real -time. We offer software development services for businesses around the globe. Vmerse InfoTech Inc have a team of skilled software developers whom you can hire to develop professional software for mobile or web, or both, meeting your precise business requirements.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Discuss & Get It Done!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/software-development-banner.jpg" alt="Software Development" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff '>
				<div className='container'>
					<div className='section-title'>
						<h2>Custom Software Development Services By Vimerse InfoTech Inc</h2>
						<p>Conventional software only provides limited results, but if you want to make the most out of the business, then custom software development is the right way. We have years of experience developing and delivering custom software solutions to industries and corporate houses. From preparing a roadmap to launch final versions and from support & maintenance to training your in-house team on software, we will be there at each step. </p>
						<p>You can opt for the following services with us: </p>
					</div>
					<div className='row'>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/it-consulting.jpg" alt="IT Consulting" className='w-100' />
									<h3>IT Consulting </h3>
								</div>
								<div className="descripition">
									<p>Our IT experts can guide you on all types of IT-related tasks. With it, you can improve business performance and competitiveness. Vimerse InfoTech Inc’s IT consultation services offerings are strategic planning, system implementation, assistance for IT operations, IT infra security implementation, and more. </p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/custom-software-development.jpg" alt="Custom Software Development" className='w-100' />
									<h3>Custom Software Development</h3>
								</div>
								<div className="descripition">
									<p>In this fast-paced world, customization is the key to success. We can help you much better with our custom software development services. We provide tailored software solutions by taking care of all business needs. It includes creating an out-of-the-box frontend & robust backend and implementing custom features and functionalities suited to all your needs.  </p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/mobile-app-development.jpg" alt="Mobile App Development" className='w-100' />
									<h3>Mobile App Development</h3>
								</div>
								<div className="descripition">
									<p>The latest tech-based mobile apps can help you achieve your goals faster, and you can reach potential customers more. We can equip your business with Android, iOS, or Cross-platform mobile apps with features aligning with your brand’s idea and sole needs. An additional advantage is user-centricity.</p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/ui-ux-designing.jpg" alt="UX/UI Designing" className='w-100' />
									<h3>UX/UI Designing</h3>
								</div>
								<div className="descripition">
									<p>For every software in this world, user experience is everything, so we take care of it utmost. Our expertise is in creating high-quality UX/UI components that a business and its users find fantastic. Our UX/UI team is proficient and knows what it takes to create an incomparable user-interfaces.</p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/product-engineering.jpg" alt="Product Engineering" className='w-100' />
									<h3>Product Engineering</h3>
								</div>
								<div className="descripition">
									<p>We can provide you with product engineering services with the expertise to develop, deploy and maintain enterprise-grade high-quality software for business. You can have services like custom enterprise software development, enterprise mobility software, data management, and more with us. </p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/team-augmentation.jpg" alt="Team Augmentation" className='w-100' />
									<h3>Team Augmentation</h3>
								</div>
								<div className="descripition">
									<p>Our goal is to assist organizations in overcoming the challenges associated with hiring IT resources as an extension to their existing team. Availing Vimerse InfoTech Inc's IT staff augmentation services, you can access the pool of resources supporting your staffing needs.</p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/dedicated-team.jpg" alt="Dedicated Team" className='w-100' />
									<h3>Dedicated Team</h3>
								</div>
								<div className="descripition">
									<p>If you need dedicated resources for custom software development who can work at your pace to deliver the software that meets your needs. In that case, we can provide you with the best resources. Hire a dedicated team from Vimerse InfoTech Inc, and leverage their expertise for developing a scalable product.</p>
								</div>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='image-title-box'>
								<div className='image-title'>
									<img src="../images/software-development/support-maintenance.jpg" alt="Support & Maintenance" className='w-100' />
									<h3>Support & Maintenance</h3>
								</div>
								<div className="descripition">
									<p>We are not only limited to developing the software; we can also assist you in maintaining or updating your existing software. From monitoring to improving functionality, you can utilize the expertise of our tech professionals. </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>The Software Development Lifecycle We Follow</h2>
						<p>We use a proven process to develop innovative software solutions that positively impact customers. Here is our process. </p>
					</div>
					<div className='row'>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/payroll.svg' alt='Requirement Collection' />
								</div>
								<h3>Requirement Collection</h3>
								<p>It all starts with requirement collection, where we will collect requirements from you and analyze them together to reach a common goal of developing professional software for your business. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/ui-ux.svg' alt="Designing" />
								</div>
								<h3>Designing</h3>
								<p>The next crucial step is designing the software. First, we will make and show you a wireframe that shows what your software will look like. Once you approve the wireframe or after any changes, we create the software design.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/web-development.svg' alt='Development' />
								</div>
								<h3>Development</h3>
								<p>Here our experts will write actual code for developing the front and backend of your software. We use the latest tech stack to develop the software to make it as per the latest trends. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/testing.svg' alt='Quality Analysis' />
								</div>
								<h3>Quality Analysis</h3>
								<p>Once the coding is finished, we will test your software on real conditions to eliminate the last bug and bring out its real potential. Once the rigorous testing is over, we will deliver the final version to you. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/maintenance.svg' alt='Support & Maintenance' />
								</div>
								<h3>Support & Maintenance</h3>
								<p>After delivering software to you will also help to maintain it. In any case of any issue, we will be there to resolve all issues with it. We make sure of the flawless working of the software. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default SoftwareDevelopment
