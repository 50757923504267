import React, { useEffect, useState } from 'react';
import "./Blog.scss";
import { Link } from 'react-router-dom';
import BlogPostList from './BlogPostList';
const BlogPost = () => {

    const [width, setWidth] = useState(0);
    useEffect(() => {
        const handleWindowSizeChange = () => setWidth(window.innerWidth);
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => window.removeEventListener("resize", handleWindowSizeChange);
    }, [width]);

    return (
        <>
            <section className="blog-area bg-f4f6fc repeat-section">
                <div className="container">
                    <div className="section-title">
                        <h2>Our Blog</h2>
                        <p>Knowledge is for sharing, so here are some blogs that we would like to share with you. </p>
                    </div>

                    <div className="row justify-content-center">
                        <BlogPostList />
                    </div>

                    <div className='row'>
                        <div className="col-lg-12 col-md-12">
                            <div className="blog-notes">
                                <p>Insights to help you do what you do better, faster and more profitably. <Link to="/blog">read full blog</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogPost;