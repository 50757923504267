import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BankingFinance = () => {
	return (
		<>
			<Helmet>
				<title>Banking & Finance | Vimerse InfoTech</title>
				<meta name="description" content="Revolutionize your banking and finance services with modern solutions. Vimerse InfoTech Inc helps Fintech businesses to meet their goals." />
				<link rel="canonical" href="https://vimerseit.com/industries/banking-and-finance" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Banking & Finance</h1>
								</div>
								<p>Redefine your banking and finance operations with innovative solutions. </p>
								<p>
									The banking and finance industry is an evergreen and dynamically progressing industry. Now with technology, traditional practices have been replaced. If you are a fintech firm or deal in banking and finance, we can help you to remaster your services with cutting-edge solutions. Vimerse InfoTech Inc provides new-generation solutions to Banking firms, NBFCs, and Fintech brands.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Revolutionize Your Fintech Services!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/banking-and-finance.jpg" alt='Banking & Finance' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Banking & Finance Solutions That Brings Change</h2>
						<p>We help banking and finance businesses to overcome the difficulties they face while providing services to their customers. Our professionals will assist you with everything from consultancy to solution implementations.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Technology Consultancy" width="60px" />
								</div>
								<h3>Technology Consultancy</h3>
								<p>As a technology consultancy firm, we can assist you in designing strategy and technology-based solutions that can help you to achieve your goals. Leverage our expertise for assessment, implementation, and support.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analytics.svg' alt="Data Analytics" width="60px" />
								</div>
								<h3>Data Analytics</h3>
								<p>Banking is one of the sectors where tons of data is generated, and managing the customers' data is one of the daunting tasks. Our data science professionals will help you to tackle all the challenges and create a perfect roadmap for all your needs.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/security.svg' alt="Cybersecurity" width="60px" />
								</div>
								<h3>Cybersecurity</h3>
								<p>Most cyber-attacks happen with banking systems, so it is better to prevent them using innovative and secure solutions. Our experts can help you to prevent any cyberattacks and keep your sensitive data safe.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/chain.svg' alt="Blockchain" width="60px" />
								</div>
								<h3>Blockchain</h3>
								<p>Blockchain is a trending technology that suits the banking sector well due to its security mechanism. Vimerse InfoTech Inc can help you to adopt the Blockchain solutions & practices aligning your all needs.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/cloud.svg' alt="Cloud Solutions" width="60px" />
								</div>
								<h3>Cloud Solutions</h3>
								<p>Cloud technology is the best to scale up businesses, and it also works fine with banking. If you are looking for banking cloud solutions, we can provide you with skilled resources and solutions.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/goverment.svg' alt="Banking Software Development" width="60px" />
								</div>
								<h3>Banking Software Development</h3>
								<p>Supercharge your banking operations and practices with new-generation banking software and create the best experience of all services for your customers, from KYC to opening of bank accounts and other activities.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Let’s take your Banking and Financial services to the next level.</h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Tap Here To Know More!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Why Vimerse InfoTech Inc For Banking & Finance Solutions?</h2>
						<p>With us, you will have talented professionals who can help you to overcome all your challenges related to your banking services. Multiple reasons make Vimerse InfoTech Inc is your perfect partner for cutting-edge solutions.</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Expert Guidance</h3>
								<p>Our team of professionals has extensive expertise in providing consultation and solution designing for the banking and finance sector. So, with us, you will have high-quality experience in guidance and banking solutions.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Cost-Optimization</h3>
								<p>Our knowledge and network of professionals allow us to deliver cost-effective solutions for all sizes of banking & finance businesses, Fintech firms, NBFCs, Insurance firms and more.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Resource Allocation</h3>
								<p>We can provide whatever resources you need to remaster your banking services. We have a proven track record in providing the best experience of resource allocation to diverse businesses.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Scalability</h3>
								<p>At any moment, you need to expand your business; we would be happy to assist you with that. We understand the banking sector's needs, so that you can rely on us for a scalable solution.</p>
							</div>
						</div>

					</div>
				</div>
			</section>

			{/* <Portfollio customClass="bg-f3f7ff" /> */}
		</>
	);
}

export default BankingFinance
