import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Goverment = () => {
	return (
		<>
			<Helmet>
				<title>Government | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc is a leading services provider of innovative  IT Solutions for the Government and Public Sector to improve citizens' lives." />
				<link rel="canonical" href="https://vimerseit.com/industries/goverment" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Goverment</h1>
								</div>
								<p>
									Increase citizen participation and improve governance experiences with new-generation solutions.
								</p>
								<p>
									Vimerse InfoTech Inc is a seasoned provider of IT services and consultancy. Partner with us to implement modern solutions to address the difficulties that citizens face under your government. Our capabilities can assist you in providing better public sector services with the help of applications, software, and the online environment. To make government services more simple, we assist governments.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Make Governance Effortless!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/government-industry.jpg" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>New-Gen E-Governance Solutions For Government & Its Citizens</h2>
						<p>Our cutting-edge eGovernance solutions bring government up to speed, streamlines service processes, and serve citizens better. From cloud-based services to smart cities, make governance more effective & efficient. The solutions we provide:</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/adaptability.svg' alt="Digital Citizenship Management" width="60px" />
								</div>
								<h3>Digital Citizenship Management</h3>
								<p>Our digital citizenship management solution helps the government to manage the citizens and their data more securely. On the other hand, it helps citizens to have the best experience of citizenship services.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/improve.svg' alt="Workforce Automation" width="60px" />
								</div>
								<h3>Workforce Automation</h3>
								<p>Improve your government employees' efficiency and productivity via a cutting-edge automation process and save their time to work for the citizens. We can help you to manage your workforce better with IT solutions.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/monitoring.svg' alt="Supervising & Monitoring" width="60px" />
								</div>
								<h3>Supervising & Monitoring</h3>
								<p>IT-backed supervising and monitoring solutions help government to keep an eye on all their operations to improve their governance. Our modern IT solutions for the public sector will help you to improve the whole governance process.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/implementation.svg' alt="Smart City Implementation" width="60px" />
								</div>
								<h3>Smart City Implementation</h3>
								<p>Make your city future-ready with the help of smart city solutions leveraging trending technologies such as IoT, AI & ML, and many more. We can help you implement it the best way possible by leveraging our knowledge.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/security.svg' alt="Information Security" width="60px" />
								</div>
								<h3>Information Security</h3>
								<p>A government's greatest challenge is keeping citizens' data secure. Vimerse InfoTech Inc can assist you in making it safer. We have a team of experienced data science professionals  who can help you with this.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/goverment.svg' alt="Governance Portal" width="60px" />
								</div>
								<h3>Governance Portal</h3>
								<p>Create a governance portal with the help of our professionals that facilitates effective governance and streamline administration. It will help you for effective decision making, streamlined communication with Citizens, and more.</p>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Streamline Your Public Services And Have An Automated Workflow for Administration With Modern IT solutions for Public Sector.</h3>
							{/* <p>Improve patient care, enhance data security, and optimize operations with our customized IT solutions.</p> */}
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Let’s Discuss!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Reshape Your Government Services Mechanism</h2>
						<p>Vimerse InfoTech can be your next technology partner for developing end-to-end e-governance solutions tailored to your needs. We provider:</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Dedicated Resources</h3>
								<p>You can hire a team of dedicated resources for all types of government IT solutions with us. We provide you a with team highly qualified and experienced team of IT professionals.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Scalability</h3>
								<p>Does not matter what kind of IT solutions you are using for better governance; we can make it more effective. Scale up your services using mobile & web solutions from talented experts.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>User Centricity</h3>
								<p>We aim to design governance solutions that are user-centric, so citizens can interact with them easily, including data security, at the time of use.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Seamless Interactions</h3>
								<p>We have the right expertise and resources to partner with you for government solutions that offer the best experience of seamless interactions between you and citizens.</p>
							</div>
						</div>

					</div>
				</div>
			</section>

			{/* <Portfollio customClass="bg-f3f7ff" /> */}
		</>
	);
}

export default Goverment
