import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const SubmitResume = () => {

	const [isLoading, setIsLoading] = useState(false);
	const [thankYouText, setThankYouText] = useState(false);

	const supportedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

	const SignupSchema = Yup.object().shape({
		applyingFor: Yup.string().required('Applying for is required.'),
		firstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!').required('Your first name is required.'),
		lastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!').required('our last name is required.'),
		phonenum: Yup.string().required('Your phone is required.'),
		email: Yup.string().email('Invalid email').required('Email is required.'),
		yourQualification: Yup.string().required('Your qualification is required.'),
		GraduationYear: Yup.string().required('Your graduation year is required.'),
		CollegeName: Yup.string().required('Your college name is required.'),
		Country: Yup.string().required('Your country is required.'),
		city: Yup.string().required('Your city is required.'),
		// refer: Yup.string().required('This field is required.'),
		// experience: Yup.string().required('This field is required.'),
		currentCompany: Yup.string().required('This field is required.'),
		position: Yup.string().required('This field is required.'),
		currentCTC: Yup.string().required('Your current CTC is required.'),
		totalYearOfExperience: Yup.string().required('This field is required.'),
		// personName: Yup.string().required('This field is required.'),
		file: Yup.mixed().nullable().required("Resume is required!")
			.test(
				"FILE_FORMAT",
				"Uploaded file has unsupported format.",
				(value) => !value || (value && (typeof value === "object" ? supportedFileTypes.includes(value?.type) : true))
			),
	});

	const sendEmail = (values) => {
		setIsLoading(true);

		emailjs
			.send('service_1y1h4mb', 'template_n6g1o0e', values, 'oSJC6J_sLj3scVbYj')
			.then((response) => {
				setIsLoading(false);
				setThankYouText(true);
				console.log('Email sent successfully!', response);
				// Handle success, e.g., show a success message to the user
			})
			.catch((error) => {
				setIsLoading(false);
				console.error('Error sending email:', error);
				// Handle error, e.g., show an error message to the user
			});
	};

	return (
		<>
			<section className='ptb-100'>
				<div className='container'>
					{!thankYouText ? (
						<>
							<div className='section-title'>
								<h2>Submit Resume</h2>
							</div>
							<Formik
								initialValues={{
									applyingFor: '',
									firstName: '',
									lastName: '',
									email: '',
									phonenum: '',
									yourQualification: '',
									GraduationYear: '',
									CollegeName: '',
									Country: '',
									city: '',
									// refer: '',
									// experience: '',
									currentCompany: '',
									position: '',
									currentCTC: '',
									totalYearOfExperience: '',
									file: null,
									// personName: '',
								}}
								validationSchema={SignupSchema}
								onSubmit={sendEmail}
							>
								{({ errors, touched, field, form }) => (
									<Form className='form-resume-submit'>
										<div className='row'>
											<div className='col-lg-12'>
												<div className='form-group'>
													<label htmlFor="applyingFor">Applying For</label>
													<Field name="applyingFor" as="select" className="form-control">
														<option value="">---</option>
														<option value="Salesforce Developer">Salesforce Developer</option>
														<option value="Salesforce Tech Lead">Salesforce Tech Lead</option>
														<option value="Project Manger">Project Manger</option>
														<option value="Business Analyst">Business Analyst</option>
														<option value="Digital Marketing Executive">Digital Marketing Executive</option>
														<option value="Digital Marketing Manager">Digital Marketing Manager</option>
														<option value="Web Developer">Web Developer</option>
														<option value="PHP Developer">PHP Developer</option>
														<option value="Human Resource Executive">Human Resource Executive</option>
														<option value="Frontend Developer">Frontend Developer</option>
														<option value="Sales Intern">Sales Intern</option>
														<option value="Graphic Designer">Graphic Designer</option>
														<option value="Technical Content Writer">Technical Content Writer</option>
													</Field>
													<ErrorMessage name="applyingFor" render={msg => <div className='error'>{msg}</div>} />
													{/* {errors.applyingFor && touched.applyingFor ? (
												<div className='error'>{errors.applyingFor}</div>
											) : null} */}
												</div>
											</div>
											<div className='col-lg-12'>
												<label htmlFor="personalDetails">Personal Details</label>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="firstName" className="form-control" placeholder="Your First Name" />
													<ErrorMessage name="firstName" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="lastName" className="form-control" placeholder="Your Last Name" />
													<ErrorMessage name="lastName" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="email" type="email" className="form-control" placeholder="Your Email" />
													<ErrorMessage name="email" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field type="tel" name="phonenum" className="form-control" placeholder="Phone Number" />
													<ErrorMessage name="phonenum" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-12'>
												<label htmlFor="personalDetails">Educational Details</label>
											</div>
											<div className='col-lg-4'>
												<div className='form-group'>
													<Field name="yourQualification" className="form-control" placeholder="Your Qualification" />
													<ErrorMessage name="yourQualification" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='form-group'>
													<Field name="GraduationYear" className="form-control" placeholder="Graduation Year" />
													<ErrorMessage name="GraduationYear" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='form-group'>
													<Field name="CollegeName" className="form-control" placeholder="College Name" />
													<ErrorMessage name="CollegeName" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-12'>
												<label>Location</label>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="Country" className="form-control" placeholder="Your Country Name" />
													<ErrorMessage name="Country" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="city" className="form-control" placeholder="City" />
													<ErrorMessage name="city" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											{/* <div className='col-lg-12'>
												<label>Did Someone Refer You?</label>
											</div>
											<div className='col-lg-12'>
												<div className='form-group' role="group" aria-labelledby="my-radio-group">
													<span>
														<Field type="radio" name="refer" value="Yes" />
														Yes
													</span>
													<span>
														<Field type="radio" name="refer" value="No" />
														No
													</span>
													{errors.refer && touched.refer ? <div className='error'>{errors.refer}</div> : null}
												</div>
											</div> */}
											{/* <div className='col-lg-6'>
												<div className='form-group'>
													<Field name="personName" className="form-control" placeholder="Person name" />
													{errors.personName && touched.personName ? (
														<div className='error'>{errors.personName}</div>
													) : null}
												</div>
											</div>
											<div className='col-lg-12'>
												<label>Do You Have Any Job Experience?</label>
											</div>
											<div className='col-lg-12'>
												<div className='form-group' role="group" aria-labelledby="my-radio-group">
													<span>
														<Field type="radio" name="experience" value="Yes" />
														Yes
													</span>
													<span>
														<Field type="radio" name="experience" value="No" />
														No
													</span>
													{errors.experience && touched.experience ? <div className='error'>{errors.experience}</div> : null}
												</div>
											</div> */}
											<div className='col-lg-12'>
												<label>Experience</label>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="currentCompany" className="form-control" placeholder="Current Company" />
													<ErrorMessage name="currentCompany" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="position" className="form-control" placeholder="Position" />
													<ErrorMessage name="position" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="currentCTC" className="form-control" placeholder="Current CTC" />
													<ErrorMessage name="currentCTC" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-6'>
												<div className='form-group'>
													<Field name="totalYearOfExperience" className="form-control" placeholder="Total Year Of Experience" />
													<ErrorMessage name="totalYearOfExperience" render={msg => <div className='error'>{msg}</div>} />
												</div>
											</div>
											<div className='col-lg-12'>
												<label>Attach Your Resume(Only DOC and PDF supported)</label>
											</div>
											<div className='col-lg-12'>
												<div className='form-group'>
													<Field name="file" >
														{({ field, form: { setFieldValue, setFieldTouched } }) => (
															<>
																<input
																	type="file"
																	id="file"
																	onChange={event => {
																		setFieldValue('file', event.currentTarget.files[0]);
																		setFieldTouched('file', true);
																	}}
																	onBlur={field.onBlur}
																	className="file-input"
																/>
																<ErrorMessage name="file" render={msg => <div className='error'>{msg}</div>} />
															</>
														)}
													</Field>
												</div>
											</div>
											<div className='col-lg-12'>
												{/* <button type="submit" className='default-btn'>Submit <span></span></button> */}
										<button type="submit" className='default-btn' disabled={isLoading}>
											{isLoading ? (
												<span className="loader"></span>
											) : (
												<span></span>
											)}
											Submit
										</button>
											</div>
										</div>
									</Form>
								)}
							</Formik>
						</>
					) : (
						<div className='thank-you-message'>
							<img src='/images/thank-you.svg' alt="Thank you" />
							<p>Thanks for Applying</p>
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default SubmitResume;
