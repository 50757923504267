import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';

const SalesforceTraining = () => {
	return (
		<>
			<Helmet>
				<title>Best Salesforce Training Institute | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech provides job-oriented Salesforce courses in Jaipur, India for students, interns, and graduates." />
				<link rel="canonical" href="https://vimerseit.com/salesforce-training" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<section className='banner-section salesforce-training-banner'>
				<div className='container'>
					<div className='section-title max-width-unset'>
						<h1>Strengthen Your IT Skills With Vimerse InfoTech</h1>
						<p>IT Training Institute In Jaipur</p>
					</div>
					<p>
						Technical programmes can be fun! We promise you a fun, productive, and practical session in which you will be able to acquire many tips, formulas, and tactics that can boost your effectiveness and efficiency at work! As one of the top IT training institutes in Jaipur, India, we provide the best IT courses in Jaipur with a 100% placement record.
					</p>
					<Link to="/contact" className="default-btn btn-primary mt-5">
						Enroll Now
						<span></span>
					</Link>
				</div>
			</section>

			<section className='repeat-section bg-f2f2f2'>
				<div className='container'>
					<div className='section-title'>
						{/* <div className='subtitle text-red'>Meet Our Team</div> */}
						<h2 className=''>Salesforce Training Course: Administrator and App Builder Certification</h2>
						<p>Salesforce training online by Vimerse InfoTech is curated by industry professionals and will help you prepare for the Salesforce Administrator Exam (CRT 101) and the Platform App Builder (CRT 403) certification exam.
						</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12'>
							<p>
								Kickstart your digital transformation with Salesforce’s broadest and best-in-class solutions that are specifically designed for your business journey. With our Salesforce Training Institute in Jaipur India, level up your operations by connecting sales, service, marketing, eCommerce, and information technology. Get a chance to personalize your customer’s experience with a hands-on Salesforce training program for app builders and administrators. Our exclusively designed Salesforce training courses help professionals, administrators, app builders, developers, analysts, managers, and sales executives to excel in their positions like never before.
							</p>
							<ul className='list-design column-count-2'>
								<li>Affordable Fees</li>
								<li>Flexible Schedules</li>
								<li>One-on-One Doubt Clearing</li>
								<li>Certification Oriented Curriculum</li>
								<li>Placement Opportunities</li>
								<li>Real-time Project Use Case</li>
							</ul>
						</div>
						<div className='col-lg-6 col-md-12'>
							<ul class="in-list">
								<li><span>Duration:</span> 2 Months</li>
								<li><span>Session:</span> 5</li>
								<li><span>Eligibility:</span> BCA, B.Tech, Bsc.IT MCA, Msc.IT, M.Tech, PGDCA</li>
							</ul>
							<h3>Starting on 7th Aug 2023</h3>
							<Link to="/contact" className="default-btn btn-primary mt-3">
								Enroll Now
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Handpicked Salesforce Training Courses And Certifications</h2>
						<p>Develop a strong grasp of basic functionalities and interface with our dedicated Salesforce training and placement. Our expert team helps you in every step of the way tackle problems and enhance your learning, to help you maximize the potential of Salesforce for your organization.</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-6 col-md-12 mb-5 mb-lg-0">
							<div className='text-center'>
								<img src='/images/salesforce-training.png' />
							</div>
						</div>
						<div className="col-lg-6 col-md-12">
							<h3>Salesforce Certification Training Program</h3>
							<Accordion defaultActiveKey="0">
								<Accordion.Item eventKey="0">
									<Accordion.Header>What is Salesforce certification?</Accordion.Header>
									<Accordion.Body>
										Salesforce Certification is the ideal benchmark for determining the expertise and skills of cloud specialists. It will help you get started in your salesforce career. Salesforce certification can help you stand out in the competitive IT job market by allowing you to become a subject matter expert.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>How do I get certified in Salesforce?</Accordion.Header>
									<Accordion.Body>
										After completing the salesforce training at Ninehertz Academy, you will be taken through the certification procedure. Navigate to the official Salesforce website, to register for the exam. Attempt for the exam and achieve a passing score, to become certified.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>What is Salesforce certifications cost?</Accordion.Header>
									<Accordion.Body>
										The cost of the salesforce certifications ranges from $200 to $6000. The $6000 price tag is only for Salesforce Technical Architect Certification Exam, and the cost of all other salesforce certification exams is either $200 or $400.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>Shall I appear for the Salesforce certification exam after completion of the salesforce course?</Accordion.Header>
									<Accordion.Body>
										Not really as our Salesforce course covers all of the topics that are necessary for Salesforce certification. Salesforce certification guide, Salesforce certification sample questions, and Salesforce certification practice questions all will be shared by our trainer.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>
				</div>
			</section >

			<section className='repeat-section bg-f2f2f2'>
				<div className='container'>
					<div className='section-title'>
						<h2>Top Salesforce Developer Tools Covered In Our Salesforce Course, Jaipur</h2>
						<p>Scale up your skills with top Salesforce Developer Tools Covered in our Salesforce Course</p>
					</div>
					<div className='row'>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/lightning-icon.png" alt="Lightning Components" />
								<p>Lightning Components</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/developer-icon.png" alt="Developer" />
								<p>Developer</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/visualforce-icon.png" alt="Visualforce" />
								<p>Visualforce</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/salesforce-app-builder-icon.png" alt="Salesforce app builder" />
								<p>Salesforce app builder</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/apex-icon.png" alt="Apex" />
								<p>Apex</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/salesforce-admin-icon.png" alt="Salesforce Admin" />
								<p>Salesforce Admin</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/ant-migration-tool-icon.png" alt="Ant Migration Tool" />
								<p>Ant Migration Tool</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/developer-console-icon.png" alt="Developer Console" />
								<p>Developer Console</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/cucumber-icon.png" alt="Cucumber" />
								<p>Cucumber</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/salesforce-extensions-vscode-icon.png" alt="Salesforce Extensions for VS Code" />
								<p>Salesforce Extensions for VS Code</p>
							</div>
						</div>
						<div className='col-xl-2 col-lg-3 col-md-4 col-6 d-flex'>
							<div class="salesforce-tools-bx">
								<img src="../images/salesforce-training/salesforce-web-driver-icon.png" alt="Salesforce web driver" />
								<p>Salesforce web driver</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default SalesforceTraining
