import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import './WhyChoose.scss';
const WhyChoose = () => {
    return (
        <>
            <section className='repeat-section why-choose'>
                <div className="container">
                    <div className="section-title">
                        <h2>Tailored IT Solutions That Drives Real-Time Results</h2>
                        <p>
                            Find How Vimerse InfoTech Can Assist You In Accelerating Business Growth.
                        </p>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <p>It is our years of experience in providing cutting-edge IT solutions to businesses, industries, or enterprises. Our dedication makes us deliver streamlined solutions that match specific business needs. We have a team of result-oriented specialists with expertise in software development, cloud computing solutions, cybersecurity services, digital marketing strategies, and more.</p>
                            <p>If you are looking at what makes us different, then here are the reasons:</p>
                            <ul className="list-design mt-4">
                                <li>Less turn-around time </li>
                                <li>On-time solution delivery</li>
                                <li>Trained team </li>
                                <li>Proven track record</li>
                                <li>Domain expertise</li>
                                <li>Cost-effectiveness </li>
                            </ul>
                            <p className="mt-4">We assure you of a perfect solution that will be your business and customer-centric.</p>
                        </div>
                        <div className="col-lg-5">
                            <ScrollAnimation animateIn="fadeInRight" delay={50} animateOnce={true}>
                                <div className="image-radius-design">
                                    <img src='../images/why-choose.jpg' alt="Tailored IT Solutions That Drives Real-Time Results" className='img-fluid' />
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default WhyChoose