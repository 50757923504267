import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import "./Header.scss";


const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false)
    const toggleNavbar = () => {
        setCollapsed(!collapsed)
        setIsNavOpen(!isNavOpen);
        document.body.classList.toggle('nav-open');
    }
    const classOne = collapsed ? 'collapse navbar-collapse show' : 'collapse navbar-collapse ';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right' : 'navbar-toggler navbar-toggler-right collapsed';

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.navbar-area');
        const scrollTop = window.scrollY;
        scrollTop >= 140 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const [isNavShown, setIsNavShown] = useState(false);
    const [isNavShown2, setIsNavShown2] = useState(false);
    const handleToggleClick = () => {
        setIsNavShown(!isNavShown);
    };
    const handleToggleClick2 = () => {
        setIsNavShown2(!isNavShown2);
    };

    const [isUserToggle, setIsUserToggle] = useState(false);

    const handleClick = () => {
        setIsUserToggle(!isUserToggle);
    };
    return (
        <>
            <div id="navbar" className="navbar-area">
                <div className="main-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <NavLink to="/">
                                <div className="navbar-brand">
                                    <img src='../images/logo.png' alt='Vimerse InfoTech' />
                                </div>
                            </NavLink>

                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarCustom" aria-controls="navbarCustom"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarCustom">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink to="/" className="nav-link" onClick={toggleNavbar}>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/about" className="nav-link" onClick={toggleNavbar}>
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/services" onClick={toggleNavbar}>
                                            Services <i className='bx bx-chevron-down'></i>
                                        </NavLink>
                                        <div className='mobile-togg-btn toggle' onClick={handleToggleClick}>
                                            <i className='bx bx-chevron-down'></i>
                                        </div>

                                        <ul className={isNavShown ? 'dropdown-menu show' : 'dropdown-menu'}>
                                            <li className="nav-item">
                                                <NavLink to="/strategic-staffing" className="nav-link" onClick={toggleNavbar}>
                                                    Strategic Staffing
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/staff-augmentation" className="nav-link" onClick={toggleNavbar}>
                                                    Staff Augmentation
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/rpo-services" className="nav-link" onClick={toggleNavbar}>
                                                    RPO Services
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/software-development" className="nav-link" onClick={toggleNavbar}>
                                                    Software Development
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/mobile-app-development" className="nav-link" onClick={toggleNavbar}>
                                                    Mobile App Development
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/website-design-and-development" className="nav-link" onClick={toggleNavbar}>
                                                    Website Development
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/digital-marketing-and-brand-imaging" className="nav-link" onClick={toggleNavbar}>
                                                    Digital Marketing
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/project-management" className="nav-link" onClick={toggleNavbar}>
                                                    Project Management
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/oracle-hcm" className="nav-link" onClick={toggleNavbar}>
                                                    Oracle HCM
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/amazon-web-services" className="nav-link" onClick={toggleNavbar}>
                                                    Amazon Web Services
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink to="/cloud-services" className="nav-link" onClick={toggleNavbar}>
                                                    Cloud Services
                                                </NavLink>
                                            </li> */}
                                            <li className="nav-item">
                                                <NavLink to="/salesforce-consulting-services" className="nav-link" onClick={toggleNavbar}>
                                                    Salesforce Consulting Services
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/data-science-consulting" className="nav-link" onClick={toggleNavbar}>
                                                    Data Science Consulting
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/industries" onClick={toggleNavbar}>
                                            Industries <i className='bx bx-chevron-down'></i>
                                        </NavLink>
                                        <div className='mobile-togg-btn toggle' onClick={handleToggleClick2}>
                                            <i className='bx bx-chevron-down'></i>
                                        </div>

                                        <ul className={isNavShown2 ? 'dropdown-menu show' : 'dropdown-menu'}>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/goverment" onClick={toggleNavbar}>
                                                    Government
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/information-technology" onClick={toggleNavbar}>
                                                    Information Technology
                                                </NavLink>
                                            </li> */}
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/engineering-manufacturing" onClick={toggleNavbar}>
                                                    Engineering Manufacturing
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/banking-and-finance" onClick={toggleNavbar}>
                                                    Banking & Finance
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/retail-and-ecommerce" onClick={toggleNavbar}>
                                                    Retail & E-commerce
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/healthcare-development" onClick={toggleNavbar}>
                                                    Healthcare Solutions
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/telecommunications" onClick={toggleNavbar}>
                                                    Telecommunications
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/education-and-elearning" onClick={toggleNavbar}>
                                                    Education & E-learning
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/travel-and-hospitality" onClick={toggleNavbar}>
                                                    Travel & Hospitality
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/food-and-restaurants" onClick={toggleNavbar}>
                                                    Food & Restaurants
                                                </NavLink>
                                            </li> */}
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/industries/media-and-entertainment" onClick={toggleNavbar}>
                                                    Media & Entertainment
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/talent-pool" className="nav-link" onClick={toggleNavbar}>
                                            Talent Pool
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/career" className="nav-link" onClick={toggleNavbar}>
                                            Career
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/blog" className="nav-link" onClick={toggleNavbar}>
                                            Insights
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                            <div className="others-options ms-auto">
                                <NavLink to="/contact" >
                                    <div className="default-btn">
                                        Contact Us<span></span>
                                    </div>
                                </NavLink>
                                <div className='user-login-dropdown'>
                                    <div className='user-dropdown-button' onClick={handleClick}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28">
                                            <path d="M16,4a5,5,0,1,1-5,5,5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7,7,7,0,0,0-7-7ZM26,30H24V25a5,5,0,0,0-5-5H13a5,5,0,0,0-5,5v5H6V25a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7Z" transform="translate(-6 -2)" />
                                        </svg>
                                    </div>
                                    {isUserToggle && (
                                        <div className='user-dropdown-list'>
                                            <a href='https://vimerse.greythr.com/' rel='noreferrer' target='_blank' onClick={handleClick}>
                                                Greythr Login
                                            </a>
                                            <a href='https://talenthirecls11.ceipal.com/pages/signin' rel='noreferrer' target='_blank' onClick={handleClick}>
                                                Ceipal Login
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}



export default Header;
