import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            navigate('/');
        }, 2000);

        return () => clearTimeout(redirectTimer);
    }, [navigate]);
    return (
        <div className='error-page-section'>
            <div className='error-image'>
                <img src='../images/error-image.svg' alt='404 Page Not Found' />
            </div>
            <div className='error-content'>
                <h1>404 Page Not Found</h1>
                <p>The requested page does not exist.</p>
                <Link to="/" className="default-btn btn-primary mt-5">
                    Go to Home
                    <span></span>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;