import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const TravelHospitality = () => {
	return (
		<>
			<Helmet>
				<title>Travel & Hospitality | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc offers travel and hospitality IT solutions that help you improve efficiency, customer service, and revenue. We develop apps and software for the travel industry." />
				<link rel="canonical" href="https://vimerseit.com/industries/travel-and-hospitality" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Travel & Hospitality</h1>
								</div>
								<p>
									Give your customers the best experience of your travel services with innovative solutions.
								</p>
								<p>
									Vimerse InfoTech Inc is a leading services and solutions provider to the travel industry, where you will have some key advantages such as cost-effectiveness and scalability. There is considerable demand for modern solutions for the travel and hospitality industry, and we cater for them with our expertise. Travel patterns are dynamically changing, so stay ahead of the curve with innovative travel solutions.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Discuss & Develop It!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/travel-hospitality.jpg" alt='Travel & Hospitality' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Expertise That You Can Leverage For Your Travel Business </h2>
						<p>We provide best-in-class solutions to travel & hospitality brands to make their business thrive. Our solutions cover</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Consultancy & Strategy" width="60px" />
								</div>
								<h3>Consultancy & Strategy</h3>
								<p>At Vimerse InfoTech Inc, you will have consultancy aligning with your business mechanism and vision. We can help you to prepare a perfect roadmap to take your travel & hospitality business to the next level.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/solution2.svg' alt="IT Services" width="60px" />
								</div>
								<h3>IT Services</h3>
								<p>Have an end-to-end IT service for all your travel & tourism business needs. Our experts can provide end-to-end digital IT solutions such as website and mobile app development.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/support-maintenance.svg' alt="Virtual Travel Assistant" width="60px" />
								</div>
								<h3>Virtual Travel Assistant</h3>
								<p>Leverage the power of AI and Machine learning for your business. Have a virtual travel assistant to help your customers out of the way. It will help you to deliver a personalized experience to your customers.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analytics.svg' alt="Reporting & Analytics" width="60px" />
								</div>
								<h3>Reporting & Analytics</h3>
								<p>Make informed decisions about your business with the help of reporting and analytics software. We can provide you with an analytics solution that will help you manage your data best and create insightful reports.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/travel-management.svg' alt="Travel Management Software" width="60px" />
								</div>
								<h3>Travel Management Software</h3>
								<p>Get better resource planning and streamline all your operations with the help of cutting-edge travel management software. It will help you to deliver all your travel and hospitality services in the best way.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/crm-software.svg' alt="Travel CRM" width="60px" />
								</div>
								<h3>Travel CRM</h3>
								<p>Manage customer relationships, track leads, manage bookings, and provide out-of-the-box customer support using travel CRM software developed by industry professionals with cost-effectiveness.</p>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Stay ahead of the competition in the travel industry with game-changing travel & hospitality solutions and grow with the trends.</h3>
							{/* <p>Improve patient care, enhance data security, and optimize operations with our customized IT solutions.</p> */}
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Unlock Power of Business Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>The Benefits You Will Have</h2>
						<p>If you are looking for a way to improve your travel agency Vimerse InfoTech Inc is here to assist you the best. With us, you will have the following benefits</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Better Decision Making</h3>
								<p>The growth of a business depends on what kind of decision you take. Our solutions can help you monitor your operations and track and analyze the data to make the right decisions.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Cost-effectiveness</h3>
								<p>We strive to provide cost-effective solutions to the travel industry so businesses can manage their budget effectively. We value each penny of our client’s investment.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Flexibility</h3>
								<p>You only need to share your requirements with us; we can develop solutions as per your needs. Our team of experts will be with you from end to end.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Industry Expertise</h3>
								<p>We have the expertise to design, develop, and deploy solutions for travel & hospitality businesses. We have a team of talented professionals having proven track records.</p>
							</div>
						</div>

					</div>
				</div>
			</section>

			{/* <Portfollio customClass="bg-f3f7ff" /> */}
		</>
	);
}

export default TravelHospitality
