import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import CommanServices from '../../components/CommanServices/CommanServices';
import Portfollio from '../../components/Portfollio/Portfollio';
import { Helmet } from 'react-helmet';

const Services = () => {
	return (
		<>
			<Helmet>
				<title>Services That Accelerates Business Growth | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc provides a wide range of services such as HR consultancy, IT Consultancy, Staff Augmentation and more that drive real-time results." />
				<link rel="canonical" href="https://vimerseit.com/services" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Services That Accelerates Business Growth</h1>
								</div>
								<p>Leverage our range of services for your business that drives results.  </p>
								<p>
									At Vimerse InfoTech Inc, we offer a wide range of services, from consultancy to IT solutions and strategic staffing to cutting-edge HR solutions. Our services are available for all types of businesses, and we focus on delivering real-time solutions to overcome business-based challenges.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Connect Now!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/service-banner.jpg" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<CommanServices />

			<section className='repeat-section our-success-section'>
				<div className='container'>
					<div className='success-bg'>
						<div className='section-title max-width-unset'>
							<div className='subtitle text-red'>Achieving Success with Vimerse InfoTech Inc</div>
							<h2 className='text-white'>Strategies and Best Practices for Building a Thriving Tech Business</h2>
						</div>
						<div className='row'>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>180+</h3>
									<p>Projects Launched </p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>5+</h3>
									<p>Years of Experience</p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>75+</h3>
									<p>Dedicated Developers</p>
								</div>
							</div>
							<div className='col-lg-3 col-md-6 d-flex'>
								<div className='success-bx'>
									<h3>90%</h3>
									<p>Client Retention</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default Services
