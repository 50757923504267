import React from "react";
const TechnologyTechStack = () => {
    return (
        <>
            <section className='client-logos'>
                <div className="container">
                    <div className="section-title ">
                        <h2>Tech Stack</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-1.png" alt="Technology" />
                                <p>Adobe Experience Manager</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-2.png" alt="Technology" />
                                <p>Big Data</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-3.png" alt="Technology" />
                                <p>Cloud Services</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-4.png" alt="Technology" />
                                <p>Cyber Security</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-5.png" alt="Technology" />
                                <p>Data Analytics</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-6.png" alt="Technology" />
                                <p>Data engineer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-7.png" alt="Technology" />
                                <p>DBA</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-8.png" alt="Technology" />
                                <p>Devops</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-9.png" alt="Technology" />
                                <p>Embedded Engineer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-10.png" alt="Technology" />
                                <p>ETL Tester</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-11.png" alt="Technology" />
                                <p>Full Stack</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-12.png" alt="Technology" />
                                <p>Hadoop</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-13.png" alt="Technology" />
                                <p>IBM Sterling</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-14.png" alt="Technology" />
                                <p>IOT</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-15.png" alt="Technology" />
                                <p>Java</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-16.png" alt="Technology" />
                                <p>Jira Confluence</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-17.png" alt="Technology" />
                                <p>Linux Engineer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-18.png" alt="Technology" />
                                <p>Network Engineering</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-19.png" alt="Technology" />
                                <p>Oracle</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-20.png" alt="Technology" />
                                <p>PEGA</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-21.png" alt="Technology" />
                                <p>PHP</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-22.png" alt="Technology" />
                                <p>Power BI</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-24.png" alt="Technology" />
                                <p>Project manager</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-25.png" alt="Technology" />
                                <p>QA</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-26.png" alt="Technology" />
                                <p>ROR</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-27.png" alt="Technology" />
                                <p>Salesforce</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-28.png" alt="Technology" />
                                <p>SAP</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-29.png" alt="Technology" />
                                <p>Frontend Development</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-30.png" alt="Technology" />
                                <p>System Administrator</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-31.png" alt="Technology" />
                                <p>Unix Engineer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-32.png" alt="Technology" />
                                <p>UI-UX</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-6 d-flex">
                            <div className="tech-item w-100">
                                <img src="../images/technology-icons/technology-icon-33.png" alt="Technology" />
                                <p>.NET</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default TechnologyTechStack