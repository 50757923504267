import React from 'react';
import { Helmet } from 'react-helmet';

const TermsConditions = () => {
	return (
		<>
			<Helmet>
				<title>Terms and Conditions | Vimerse InfoTech</title>
				<meta name="description" content="" />
				<link rel="canonical" href="https://vimerseit.com/terms-conditions" />
			</Helmet>

			<section className='repeat-section privacy-policy-section'>
				<div className='container'>
					<div className='section-title'>
						<h1>Terms and Conditions</h1>
						<p>Welcome to vimerseit.com!</p>
					</div>
					<div className='row'>
						<div className='col-12'>
							<p>These terms and conditions outline the rules and regulations for using Vimerse InfoTech Inc's Website, located at https://www.vimerseit.com.</p>
							<p>
								By accessing this Website, we assume you accept these terms and conditions. Only continue to use Vimerseit in if you agree to take all of the terms and conditions stated on this page.
								The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You", and "Your" refers to you, the person who logs on to this Website and is compliant with the Company's terms and conditions. "The Company", "Ourselves", "We", "Our", and "Us" refers to our Company. "Party", "Parties", or "Us" refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client most appropriately for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services by and subject to, prevailing law of us. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they are taken as interchangeable and therefore as referring to the same.
							</p>
							<h3>Cookies</h3>
							<p>We employ the use of cookies. By accessing vimerseit.com, you agreed to use cookies in agreement with Vimerse InfoTech Inc's Privacy Policy.</p>
							<p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Our Website uses cookies to enable the functionality of certain areas to make it easier for people visiting our Website. Some of our affiliate/advertising partners may also use cookies.</p>
							<h3>License</h3>
							<p>Unless otherwise stated, Vimerse InfoTech Inc and/or its licensors own the intellectual property rights for all material on vimerseit.com All intellectual property rights are reserved. You may access this from vimerseit.com for your personal use, subject to restrictions set in these terms and conditions. </p>
							<p>You must not:</p>
							<ul className='list-design mb-5'>
								<li>Republish material from vimerseit.com</li>
								<li>Sell, rent or sub-license material from vimerseit.com</li>
								<li>Reproduce, duplicate or copy material from vimerseit.com</li>
								<li>Redistribute content from vimerseit.com</li>
							</ul>
							<p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with keping out business and operations nature in mind.</p>
							<p>Parts of this Website offer an opportunity for users to post and exchange opinions and information in certain areas of the Website. Vimerse InfoTech Inc does not filter, edit, publish or review Comments prior to their presence on the Website. Comments do not reflect the views and opinions of Vimerse InfoTech Inc, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Vimerse InfoTech Inc shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this Website.</p>
							<p>
								Vimerse InfoTech Inc reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes a breach of these Terms and Conditions.
							</p>
							<p>You warrant and represent that:</p>
							<ul className='list-design mb-5'>
								<li>You are entitled to post the Comments on our Website and have all necessary licenses and consents to do so.</li>
								<li>The Comments do not invade any intellectual property right, including without limitation, copyright, patent or trademark of any third party.</li>
								<li>The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material, which is an invasion of privacy.</li>
								<li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
							</ul>
							<p>
								You at this moment, grant Vimerse InfoTech Inc a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
							</p>
							<h3>Hyperlinking to our Content</h3>
							<p>The following organizations may link to our Website without prior written approval:</p>
							<ul className='list-design mb-5'>
								<li>Government agencies</li>
								<li>Search engines</li>
								<li>News organizations</li>
								<li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses.</li>
								<li>
									System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups, which may not hyperlink to our Website.
								</li>
							</ul>
							<p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
							<p>
								We may consider and approve other link requests from the following types of organizations:
							</p>
							<ul className='list-design mb-5'>
								<li>commonly-known consumer and/or business information sources</li>
								<li>dot.com community sites</li>
								<li>associations or other groups representing charities</li>
								<li>online directory distributors</li>
								<li>
									internet portals
								</li>
								<li>
									accounting, law and consulting firms
								</li>
								<li>
									educational institutions and trade associations
								</li>
							</ul>
							<p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Vimerse InfoTech Inc; and (d) the link is in the context of general resource information.</p>
							<p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
								If you are one of the organizations listed in paragraph 2 above and are interested in linking to our Website, you must inform us by sending an e-mail to Vimerse InfoTech Inc, i.e., info@vimerseit.com</p>
							<p>Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
							<p>
								Approved organizations may hyperlink to our Website as follows:
							</p>
							<ul className='list-design mb-5'>
								<li>By use of our corporate name</li>
								<li>By use of the uniform resource locator being linked to</li>
								<li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site
								</li>
							</ul>
							<p>
								No use of Vimerse InfoTech Inc's logo or other artwork will be allowed for linking absent a trademark license agreement.
							</p>

							<h3>iFrames</h3>
							<p>Without prior approval and written permission, you may not create frames around our web pages that alter in any way the visual presentation or appearance of our Website.</p>

							<h3>Content Liability</h3>
							<p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>

							<h3>Reservation of Rights</h3>
							<p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

							<h3>Removal of links from our Website</h3>
							<p>
								If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
							</p>

							<p>
								We do not ensure that the information on this Website is correct; we do not warrant its completeness or accuracy, nor do we promise to ensure that the Website remains available or that the material on the Website is kept up to date.
							</p>

							<h3>Disclaimer</h3>
							<p>
								To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our Website and the use of this Website. Nothing in this disclaimer will:

							</p>
							<ul className='list-design mb-5'>
								<li>limit or exclude our or your liability for fraud or fraudulent misrepresentation</li>
								<li>limit any of our or your liabilities in any way that is not permitted under applicable law</li>
								<li>exclude any of our or your liabilities that may not be excluded under applicable law </li>
							</ul>

							<p>
								The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
								As long as the Website and the information and services on the Website are provided free of charge, we will not be liable for any loss or damage of any nature.
							</p>


							<p>
								The information provided on timers it.in Website ("Website") is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Website or the information, products, services, or related graphics contained on the Website for any purpose. Any reliance you place on such information is, therefore, strictly at your own risk.
							</p>


							<p>
								In no event will we be liable for any loss or damage, including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of the Website?
							</p>


							<p>
								Through the Website, you may be able to link to other websites that are not under the control of Vimerse InfoTech Inc ("Company"). We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
							</p>


							<p>
								Every effort is made to keep the Website up and running smoothly. However, the Company takes no responsibility for, and will not be liable for, the Website being temporarily unavailable due to technical issues beyond our control.
							</p>


							<p>
								The content on the Website is provided for general information purposes only and does not constitute professional advice. You should seek appropriate professional advice before taking any action based on the information provided on the Website.
							</p>


							<p>
								The views and opinions expressed in any user-generated content or comments on the Website are those of the respective authors and do not necessarily reflect the official policy or position of the Company.
							</p>


							<p>
								While we strive to ensure the security and confidentiality of user information on the Website, we cannot guarantee the absolute security of data transmitted over the Internet.
								Any personal information provided by users through the Website is subject to our Privacy Policy.
							</p>


							<p>
								By using the timers it.in Website, you agree to the terms of this Disclaimer. If you do not agree with this Disclaimer, please refrain from using the Website.
							</p>


							<p>
								If you have any questions about this Disclaimer, please contact us at info@vimerseit.com
								The Company reserves the right to modify or amend this Disclaimer at any time without prior notice. It is your responsibility to review this Disclaimer periodically for any changes. Your continued use of the Website after any modifications to this Disclaimer constitutes acceptance of those modifications.
							</p>

						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default TermsConditions
