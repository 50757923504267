import React from 'react';
import { Outlet } from 'react-router-dom'

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const TheContent = () => {
	return (
		<main className="main-content">
			<Outlet />
		</main>
	);
};

export default React.memo(TheContent);
