import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HealthcareDevelopment = () => {
	return (
		<>
			<Helmet>
				<title>Healthcare Solutions | Vimerse InfoTech</title>
				<meta name="description" content="Leverage best-in-class healthcare development services and improve your service delivery mechanism. We develop healthcare solutions that drive real-time results." />
				<link rel="canonical" href="https://vimerseit.com/industries/healthcare-development" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Healthcare Solutions</h1>
								</div>
								<p>
									We ensure every healthcare brand has an ecosystem to deliver the best services.
								</p>
								<p>
									Since the Pandemic, there has been considerable demand for tailored healthcare solutions, so people must find it convenient and affordable for better healthcare. Being a reputed healthcare solutions development company Vimerse InfoTech Inc can assist you in developing a healthcare app or software based on the latest technology. Our dedicated professionals create tailored solutions to address healthcare & fitness needs and challenges.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Revolutionize Healthcare Services!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/healthcare-banner.jpg" alt='Healthcare Solutions' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Healthcare Solutions & Services That Brings Change</h2>
						<p>We can help you to deliver healthcare services with personalization to your customers. Our ranges of healthcare solutions and services include.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Healthcare Consultancy" width="60px" />
								</div>
								<h3>Healthcare Consultancy</h3>
								<p>At Vimerse InfoTech Inc, you can consult with a team of professionals who will help you to assess your business to find what type of solutions you need and how these will be compatible with improving your services.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/healthcare-apps.svg' alt="Mobile Healthcare Apps" width="60px" />
								</div>
								<h3>Mobile Healthcare Apps</h3>
								<p>People prefer mobile apps for accessing healthcare services, from booking appointments to ordering medicines. If you are in the healthcare or pharma business, we can provide a customized app to reach more customers.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/healthcare.svg' alt="Custom Healthcare Software Development" width="60px" />
								</div>
								<h3>Custom Healthcare Software Development</h3>
								<p>Get customized software to meet all your specific needs. It can help you improve the workforce's productivity and the quality of your healthcare services. Our healthcare software developers will assist you the best.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/healthcare-cloud-solutions.svg' alt="Healthcare Cloud Solutions" width="60px" />
								</div>
								<h3>Healthcare Cloud Solutions</h3>
								<p>Create a modern patient care ecosystem with Vimerse InfoTech Inc and make it available as a uniform platform for your resources and patients. Our healthcare cloud solutions offer better patient engagement and improved business processes.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/crm-software.svg' alt="Healthcare CRM Software" width="60px" />
								</div>
								<h3>Healthcare CRM Software</h3>
								<p>Take your relationship with customers to the next level with innovative healthcare CRM Software. It helps healthcare brands collect customer data, assess interactions, and improve customer relationships.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/ehr.svg' alt="EHR Software" width="60px" />
								</div>
								<h3>EHR Software</h3>
								<p>EHR stands for electronic health record software. It works as a digital platform that stores patients' medical records. Using EHR, healthcare professionals can access patient information with ease. We can make it customized to meet your goals.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Create the best experience of healthcare services using innovative and cutting-edge solutions.</h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Get Started Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Why Vimerse InfoTech Inc For Healthcare Development?</h2>
						<p>When it comes to developing healthcare solutions, Vimerse InfoTech Inc is one of the trusted service providers you can rely on. We help healthcare businesses to meet their goals smoothly.</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Proven Expertise</h3>
								<p>We possess proven expertise in developing modern healthcare solutions for businesses, hospitals & clinic chains. At Vimerse InfoTech Inc, you will find a team of experienced healthcare software developers.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Scalability</h3>
								<p>Scalability is a must to compete in a tough market competition where requirements can be changed overnight. With our solutions, you will have built-in scalability, so where you need, you can scale up your business & its operations.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Improved Decision Making</h3>
								<p>The latest technology-based solutions can help you with effective decision-making. Our solutions remain beneficial for data management & analysis, resource management, patient engagement, and more.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>One-stop Destination for all</h3>
								<p>Vimerse InfoTech Inc works as a one-stop destination for all types of healthcare businesses. We provide app and software solutions to pharma companies, healthcare startups, clinic chains, and others.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default HealthcareDevelopment
