import React from "react";
import Slider from "react-slick";
import './ClientTestiomnails.scss';

var settings = {
    dots: false,
    nav: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],

    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                // dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                nav: false,
                navText: false,
            }
        }
    ]
};
const ClientTestiomnails = () => {
    return (
        <>
            <section className='client-testimonails'>
                <div className="container">
                    <div className="section-title ">
                        <h2>What Our Clients Say</h2>
                        <p>
                            Here are a few words from satisfied clients which are like rewards for us.
                        </p>
                    </div>
                    <Slider className="testimonail-slider" {...settings}>
                        <div className="slide-bx d-flex">
                            <div className="client-bx w-100">
                                <div className="client-image">
                                    <img src='../images/user-profile.png' alt="Client Profile" />
                                </div>
                                <div className="client-name">
                                    Tom <span>/ Operations Manager</span>
                                </div>
                                <div className="client-feedbacks">
                                    Vimerse's team was incredibly professional and knowledgeable. They took the time to understand our needs and delivered a customized solution that exceeded our expectations.
                                </div>
                            </div>
                        </div>
                        <div className="slide-bx d-flex">
                            <div className="client-bx w-100">
                                <div className="client-image">
                                    <img src='../images/user-profile.png' alt="Client Profile" />
                                </div>
                                <div className="client-name">
                                    Sarah <span>/ COO of Corp</span>
                                </div>
                                <div className="client-feedbacks">
                                    Thanks to Vimerse's cloud computing solutions, we were able to scale our business quickly and efficiently. Their team was always available to answer our questions and provide support when needed.
                                </div>
                            </div>
                        </div>
                        <div className="slide-bx d-flex">
                            <div className="client-bx w-100">
                                <div className="client-image">
                                    <img src='../images/user-profile.png' alt="Client Profile" />
                                </div>
                                <div className="client-name">
                                    Michael <span>/ Marketing Manager</span>
                                </div>
                                <div className="client-feedbacks">
                                    Vimerse's digital marketing strategies have helped us increase our online presence and attract new customers. We're thrilled with the results and would highly recommend their services.
                                </div>
                            </div>
                        </div>
                        <div className="slide-bx d-flex">
                            <div className="client-bx w-100">
                                <div className="client-image">
                                    <img src='../images/user-profile.png' alt="Client Profile" />
                                </div>
                                <div className="client-name">
                                    Lisa <span>/ CIO of GHI</span>
                                </div>
                                <div className="client-feedbacks">
                                    Vimerse's cybersecurity services provided comprehensive solutions and ongoing support, giving us peace of mind knowing our business is secure.
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

            </section>
        </>
    );
}

export default ClientTestiomnails