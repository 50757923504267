import React from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../../components/ContactForm/ContactFormFooter';

const Contact = () => {
	return (
		<>
			<Helmet>
				<title>Contact Us | Vimerse InfoTech</title>
				<meta name="description" content="Contact us to hire our expert mobile app developers. Vimerse InfoTech is a top rated Web and Mobile App development company across USA, CANADA and UK." />
				<link rel="canonical" href="https://vimerseit.com/contact" />
			</Helmet>
			<section className='repeat-section contact-section'>
				<div className='container'>
					<div className='section-title'>
						<h1>Contact Us</h1>
						<p>Let's connect for the discussion. We would be happy to assist you with all your queries. Fill out the form below, and we will get back to you asap.</p>
					</div>
					<div className='row'>
						<div className='col-lg-6'>
							<h3>Send Us a Message</h3>
							<ContactForm />
							{/* <Mailer /> */}
						</div>
						<div className='col-lg-6 mt-5 mt-lg-0'>
							<div className='map'>
								{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.2939005623903!2d-96.8381625!3d32.943249099999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c26d384153065%3A0x8d9e33a3912676b5!2s14200%20Midway%20Rd%20STE%20122%2C%20Farmers%20Branch%2C%20TX%2075244%2C%20USA!5e0!3m2!1sen!2sin!4v1687368344220!5m2!1sen!2sin" width="100%" height="400px"></iframe> */}
								<iframe src="https://www.google.com/maps/d/embed?mid=1_4_GrTjuQTG4Yh4MypF_Kc_6FB009Qc&ehbc=2E312F" width="100%" height="400px"></iframe>
								{/* <iframe src="https://www.google.com/maps/d/embed?mid=1tJlH6RXQFlX6q0r_5j7NVeCzZJWWuWI&ehbc=2E312F" width="100%" height="400px"></iframe> */}
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section office-address bg-f2f2f2'>
				<div className='container-fluid'>
					<div className='section-title'>
						<h2>Our Offices</h2>
					</div>
					<div className='row row-cols-1 row-cols-md-2 row-cols-xl-5'>
						<div className='col d-flex'>
							<div className='address-item'>
								<h3><img src="/images/flag/usa-flag.svg" className='flag-icon' />USA</h3>
								<p>14200  Midway Rd, STE 122, Dallas, TX 75244</p>
								<div className='contact-item'>
									<span>Contact:</span> <a href='tel:+1 (972) 640-1235'>+1 (972) 640-1235</a>
								</div>
								<div className='contact-item'>
									<span>Fax:</span> <a href='fax:+1 (972) 214-2350'>+1 (972) 214-2350</a>
								</div>
							</div>
						</div>
						<div className='col d-flex'>
							<div className='address-item'>
								<h3><img src="/images/flag/india-flag.svg" className='flag-icon' />INDIA</h3>
								<p>A-12 Shri Kanhaiya Mansion, 1st Floor, Near Nursery Circle, Vaishali Nagar, Jaipur, Rajasthan 302021</p>
								<div className='contact-item'>
									<span>Contact:</span> <a href='tel:+91141-4025480'>+91141-4025480</a>
								</div>
							</div>
						</div>
						<div className='col d-flex'>
							<div className='address-item'>
								<h3><img src="/images/flag/india-flag.svg" className='flag-icon' />INDIA</h3>
								<p>Unit 815, JMD Megapolis, Sohna Road, Sector 48, Gurugram, Haryana 122018</p>
								<div className='contact-item'>
									<span>Contact:</span> <a href='tel:+911244726297'>+91 124 472 6297</a>
								</div>
							</div>
						</div>
						<div className='col d-flex'>
							<div className='address-item'>
								<h3><img src="/images/flag/canada-flag.svg" className='flag-icon' />CANADA</h3>
								<p>27 Lavender Pl Woodbridge, ON L4L 9B3</p>
								<div className='contact-item'>
									<span>Contact:</span> <a href='tel:+1 (972) 214-2351'>+1 (972) 214-2351</a>
								</div>
								<div className='contact-item'>
									<span>Fax:</span> <a href='fax:+1 (972) 214-2350'> +1 (972) 214-2350</a>
								</div>
							</div>
						</div>
						<div className='col d-flex'>
							<div className='address-item'>
								<h3><img src="/images/flag/uk-flag.svg" className='flag-icon' />UK</h3>
								<p>The Courtyard, 14A Sydenham Road, Croydon – CR0 2EE</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Contact
