import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SuccessStory from '../../components/SuccessStory/SuccessStory';

const HireDevelopers = () => {
	return (
		<>
			<Helmet>
				<title>Hire Dedicated Resources | Vimerse InfoTech</title>
				<meta name="description" content="Hire dedicated resources to get your projects done on time and within budget. Vimerse InfoTech Inc offers dedicated resources for hiring that you can avail as per your needs. " />
				<link rel="canonical" href="https://vimerseit.com/talent-pool" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Hire Dedicated Resources</h1>
								</div>
								<p>
									Hire dedicated resources for your next project and bring your brilliant idea to life with VimerseIT. With us, you will have-
								</p>
								<ul className='list-design'>
									<li>Flexible Hiring Models</li>
									<li>Extensive Tech Stack</li>
									<li>Realistic Budget</li>
									<li>Realistic Time Frame</li>
									<li>Guaranteed Satisfaction</li>
									<li>Open Communication</li>
								</ul>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Hire Your Own Team!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/hire-developer-banner.png" alt='Hire Dedicated Resources' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Hire Web App Developer In Different Technology Stack</h2>
						<p>Do you have a project and are looking to hire dedicated resources? Look no further; hire dedicated developers at Vimerse InfoTech Inc and get high-quality solutions within the deadline. Your project will be in responsible hands so that you can focus on your business more. </p>
					</div>

					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='hire-box'>
								<h3>Frontend Developer</h3>
								<p>At Vimerse InfoTech Inc, you can hire professional front-end developers with a proven track record of creating designs that captivate and retain users.</p>
								<ul className='hire-list-icon'>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/html.svg" alt="HTML5" />
											<span>HTML5</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/css3.svg" alt="CSS3" />
											<span>CSS3</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/angular.svg" alt="Angular" />
											<span>Angular</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/react.svg" alt="React" />
											<span>React</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/java-script.svg" alt="JavaScript" />
											<span>JavaScript</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/tailwind.svg" alt="Tailwind" />
											<span>Tailwind</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='hire-box'>
								<h3>Backend Developer</h3>
								<p>In addition to our talented designers, we also have expert back-end developers who can build robust solutions that will stand the test of time. </p>
								<ul className='hire-list-icon'>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/php.svg" alt="PHP" />
											<span>PHP</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/python.svg" alt="Python" />
											<span>Python</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/laravel.svg" alt="Laravel" />
											<span>Laravel</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/mongo-db.svg" alt="MongoDB" />
											<span>MongoDB</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/mysql.svg" alt="MySQL" />
											<span>MySQL</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/apache.svg" alt="Apache" />
											<span>Apache</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-6 col-md-12 d-flex mb-5 mb-lg-0'>
							<div className='hire-box'>
								<h3>CMS Developer</h3>
								<p>Managing content has always been challenging; our team of CMS developers will create systems that allow you to manage, update, edit, or remove content as per your needs.</p>
								<ul className='hire-list-icon'>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/wordpress.svg" alt="WordPress" />
											<span>WordPress</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/magento.svg" alt="Magento" />
											<span>Magento</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/drupal.svg" alt="Drupal" />
											<span>Drupal</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/shopify.svg" alt="Shopify" />
											<span>Shopify</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/woocommerce.svg" alt="WooCommerce" />
											<span>WooCommerce</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-6 col-md-12 d-flex mb-5 mb-lg-0'>
							<div className='hire-box'>
								<h3>Blockchain & ML</h3>
								<p>Enter the world of Blockchain and Machine learning with Vimerse InfoTech Inc our team of experienced developers is here to assist with your project.</p>
								<ul className='hire-list-icon'>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/smart-contract-development.png" alt="Smart Contract Development" />
											<span>Smart Contract Development</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/blockchain-architecture-design.png" alt="Blockchain Architecture Design" />
											<span>Blockchain Architecture Design</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/cryptocurrency-development.png" alt="Cryptocurrency Development" />
											<span>Cryptocurrency Development</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/blockchain-integration.png" alt="Blockchain Integration" />
											<span>Blockchain Integration</span>
										</div>
									</li>
									<li>
										<div className='tech-box'>
											<img src="../images/icons/ico-development.png" alt="ICO Development" />
											<span>ICO Development</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Do you have an idea that you want to convert into real software or an app?</h3>
							<p>As a leading solution provider, we offer services for your every need.</p>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Let’s talk!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Engagement Models That Are Specially Designed To Benefit Your Business And Projects</h2>
						<p>At Vimerse InfoTech Inc, we understand all business needs. That’s why we offer different engagement models for clients, allowing you to choose one that best suits you. </p>
					</div>
					<div className='row'>
						<div className='col-lg-3 col-md-6 d-flex mb-3 mb-lg-0'>
							<div className='shadow-box'>
								<div className='icon'>
									<img src='../images/icons/calendar2.svg' width={50} alt="Weekly Model" />
								</div>
								<h3>Weekly Model</h3>
								<p>Do you have a small project you want to work on? With our weekly model, you can hire dedicated developers for a limited time. It allows great flexibility in work and cost.</p>
							</div>
						</div>

						<div className='col-lg-3 col-md-6 d-flex mb-3 mb-lg-0'>
							<div className='shadow-box'>
								<div className='icon'>
									<img src='../images/icons/calendar.svg' width={50} alt="Monthly Model" />
								</div>
								<h3>Monthly Model</h3>
								<p>Hire dedicated resources as an extension of your team on a monthly basis. Our monthly hiring model is ideal for projects that require one or two months to complete. </p>
							</div>
						</div>

						<div className='col-lg-3 col-md-6 d-flex mb-3 mb-lg-0'>
							<div className='shadow-box'>
								<div className='icon'>
									<img src='../images/icons/solutions3.svg' width={50} alt="Fixed Cost Model" />
								</div>
								<h3>Fixed Cost Model</h3>
								<p>Leave behind the worries of weekly or monthly billing with a fixed-cost hiring model. Set a budget and hand over the project to us; we will take care of the rest.</p>
							</div>
						</div>

						<div className='col-lg-3 col-md-6 d-flex mb-3 mb-lg-0'>
							<div className='shadow-box'>
								<div className='icon'>
									<img src='../images/icons/time.svg' width={50} alt="Time & Material Model" />
								</div>
								<h3>Time & Material Model</h3>
								<p>If you want more flexibility in your hiring model, the time and material model is perfect for you. Services are charged based on the time and material used during the development.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>How To Hire Dedicated Developers From Vimerse InfoTech Inc?</h2>
						<p>If you want to create a solution that stands out from the rest and takes your business to the next level, you need to work with the best. That’s where we come in. Vimerse InfoTech Inc offers market-leading services allowing you to hire dedicated developers. Here’s how you can hire a dedicated team with us</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>01</span>
								</div>
								<div className="hire-box w-100">
									<h3>Inquiry</h3>
									<p>Hiring dedicated resources begins with an inquiry.Send us your contact information through the form, and we will get in touch with you as soon as possible.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>02</span>
								</div>
								<div className="hire-box w-100">
									<h3>Screening</h3>
									<p>The second step is to screen development resources. It is an important part of the hiring process. Make a list of resources for an interview.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>03</span>
								</div>
								<div className="hire-box w-100">
									<h3>Interview</h3>
									<p>Based on the shortlisting, take an interview of all potential resources to form your own team to shape your project as you wish. We ensure experienced resources.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>04</span>
								</div>
								<div className="hire-box w-100">
									<h3>Hiring Model </h3>
									<p>Choose a hiring model as per your need to turn your project into reality. We offer a variety of hiring models to assist businesses with all their requirements.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>05</span>
								</div>
								<div className="hire-box w-100">
									<h3>Start Your Project</h3>
									<p>Now, it’s time to start your project. At this stage, you will have your team from us composed of talented designers & developers, project managers and other professionals. </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section pb-0'>
				<div className='container'>
					<div className='section-title'>
						<h2>Why Do Businesses Hire Dedicated Developers From Vimerse?</h2>
						<p>With us, you can hire the best-dedicated developers for your work. As a leading IT solutions provider, Vimerse InfoTech Inc offers cost-effective IT & consultancy services for all your needs.</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<h3 className='mb-0'>Hire - Relax - Wait for Results - Go to Market</h3>
							<p>Want to hire dedicated developers? Here are some add-ons that you will have with us.</p>
							<ul className='list-design-2 mt-5'>
								<li>Seamless Communication</li>
								<li>NDA Adherence</li>
								<li>Direct Access to Resource</li>
								<li>Complete Code Security</li>
								<li>Source Code Ownership</li>
								<li>Complete Project Control</li>
								<li>Maintenance & Support</li>
								<li>Transparent Pricing</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<SuccessStory />

		</>
	);
}

export default HireDevelopers
