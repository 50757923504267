import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const WebsiteDevelopment = () => {
	return (
		<>
			<Helmet>
				<title>Website Design and Development Services | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc Offers professional website design and development services that include custom web development, web app development, ecommerce, and more." />
				<link rel="canonical" href="https://vimerseit.com/website-design-and-development" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Website Design And Development Services</h1>
								</div>
								<p>Get a stunning website for business ideated by you and developed by experts.  </p>
								<p>
									We design and develop a website that delivers tangible results to businesses. Our experienced developers and designers will create a website meeting your needs. You will have an eye-catching design, smooth navigation, and features to boost your online presence.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Get Your Website Now!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/web-development-banner.jpg" alt='Website Design And Development Company' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='repeat-section bg-f3f7ff '>
				<div className='container'>
					<div className='section-title'>
						<h2>Custom Web Development Services For Businesses</h2>
						<p>Our extensive expertise in web development services allows us to deliver the best solutions.
							Vimerse Infotech Inc’s web development services help businesses to achieve their goals with the help of exceptional solutions where you can have solutions such as an ecommerce website, web portal, and more. We will help you to implement it in the right way.
							We offer the following services;</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-12'>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/web-development.svg" alt="Custom Web Development" />
								</div>
								<h3>Custom Web Development</h3>
								<p>Developing a website from scratch or remastering an existing website, we can provide you with a tailored solution that satisfies your needs. Our team of web developers specialises in developing custom websites.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/ui-ux.svg" alt="Web Designing" />
								</div>
								<h3>Web Designing </h3>
								<p>Stunning web designs always impress customers. So have it from skilled web designers having a proven track record. We deliver out–of–the–box web designs for websites and web apps that convert and engage the customers the best.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/app-development2.svg" alt='Web App Development' />
								</div>
								<h3>Web App Development</h3>
								<p>Get business and customer-centric web applications from Vimerse InfoTech Inc and accelerate your business growth. You can expect stunning web applications with us that work seamlessly across all browsers. </p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/ecommerce-development.svg" alt="Ecommerce Development" />
								</div>
								<h3>Ecommerce Development</h3>
								<p>Transform your traditional business into an online one and make a reach to global customers. Our experts will help you to implement a profitable online shopping website where effortlessly you can sell your products.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/cms.svg" alt='Content Management Systems' />
								</div>
								<h3>Content Management Systems</h3>
								<p>CMS, aka Content Management Systems, allows users to create, edit, and manage website content without needing specialised technical knowledge. We can help you to implement a CMS as per your business needs.</p>
							</div>
						</div>
						<div className='col-xl-4 col-lg-6 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/enterprise.svg" alt='Enterprise Website Development' />
								</div>
								<h3>Enterprise Website Development</h3>
								<p>It is a must to have a strong presence online for large-scale businesses. Here we can get your back by providing an enterprise-grade website that can help you run your operations seamlessly and in an improved way.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Exceptional Web Designing And Development Solutions For Industries </h2>
						<p>Our web developers have diversified experience developing web development solutions for different industries. So, you can expect a robust solution from us per your industry needs.</p>
					</div>
					<div className='row justify-content-center mt-5'>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/goverment.svg" alt="Government" />
								</div>
								<h3>Government</h3>
								<p>We help governments to develop websites and portals for good governance.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/ecommerce-development.svg" alt="Retails & ecommerce" />
								</div>
								<h3>Retails & eCommerce</h3>
								<p>Have an outstanding ecommerce web portal from experienced web developers. </p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/eLearning-app.svg" alt="Education" />
								</div>
								<h3>Education</h3>
								<p>Impart an adequate education with an online education portal and improve educational practices.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/healthcare.svg" alt="Healthcare" />
								</div>
								<h3>Healthcare</h3>
								<p>Develop a healthcare website to overcome the challenges and make services reach easy.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/hr.svg" alt="HR App Solutions" />
								</div>
								<h3>HR App Solutions</h3>
								<p>Create a seamless HR portal for better recruitment and handling existing workforce.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/finance.svg" alt="Fintech" />
								</div>
								<h3>Fintech</h3>
								<p>Create the best experience for your fintech services and make easy reach to the customers.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/telecommunication.svg" alt="Telecommunication" />
								</div>
								<h3>Telecommunication</h3>
								<p>Get robust telecommunication web solutions for your business from experts.</p>
							</div>
						</div>
						<div className='col-xl-3 col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className="icon">
									<img src="../images/icons/entertainment-media.svg" alt="Media & Entertainment" />
								</div>
								<h3>Media & Entertainment</h3>
								<p>Launch a website to redefine entertainment and generate good revenue.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default WebsiteDevelopment
