import React from "react";
import Slider from "react-slick";
var settings = {
    dots: false,
    nav: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],

    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }
    ]
};
const ClientLogos = () => {
    return (
        <>
            <section className='client-logos'>
                <div className="container-fluid">
                    <div className="section-title ">
                        <h2>Trusted By</h2>
                    </div>
                    <Slider className="client-logos-slider testimonail-slider" {...settings}>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-1.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-2.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-4.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-5.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-7.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-8.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-9.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-10.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-13.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-15.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-16.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-17.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-19.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-20.png" alt="Client" />
                        </div>
                        <div className="logo-item">
                            <img src="../images/client-logo/client-logo-21.png" alt="Client" />
                        </div>
                    </Slider>
                </div>

            </section>
        </>
    );
}

export default ClientLogos