import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Telecommunications = () => {
	return (
		<>
			<Helmet>
				<title>Telecommunications | Vimerse InfoTech</title>
				<meta name="description" content="Unlock the power of your telecom business with the latest solutions. Streamline operations, enhance network performance, and deliver exceptional customer experiences with us." />
				<link rel="canonical" href="https://vimerseit.com/industries/telecommunications" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Telecommunications</h1>
								</div>
								<p>
									Get the best experience in IT solutions for your Telecom business and unleash the power of your business.
								</p>
								<p>
									Vimerse InfoTech Inc offers innovative solutions that help telecommunication businesses streamline their operations, increase productivity, and enhance customer satisfaction. Our experts can assist you with network management, data analytics, and customer relationship management tools; they will help you to provide seamless communication services.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Discuss!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/telecommunications.jpg" alt='Telecommunications' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Solutions We Offer For Telecom Industry</h2>
						<p>Leverage the comprehensive expertise of our experts to have desired solutions for your telecom business.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Telecom IT Consulting" width="60px" />
								</div>
								<h3>Telecom IT Consulting</h3>
								<p>Get the best telecommunication consultancy from industry experts. Our experts can assist you in making the strategy and risk identification and preparing a roadmap for future business aspects.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/adaptability.svg' alt="Business Intelligence" width="60px" />
								</div>
								<h3>Business Intelligence</h3>
								<p>If you are looking for business intelligence solutions for your telecom business, look no further Vimerse InfoTech is here to assist you the best. We can provide you with the solutions as per your needs. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/security.svg' alt="CyberSecurity" width="60px" />
								</div>
								<h3>CyberSecurity</h3>
								<p>Telecommunication is one of the primary targets for cyber attacks, so here you need a solution that can help you to prevent crucial information from it. We can help you to implement it in the best way. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analytics.svg' alt="Data Analytics" width="60px" />
								</div>
								<h3>Data Analytics</h3>
								<p>Telecommunication operations and data generation are proportional to each other, and data analytics can help you manage the data effortlessly. So, leverage the expertise of our data science professionals for it.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/app-development.svg' alt="App Development" width="60px" />
								</div>
								<h3>App Development</h3>
								<p>We can guarantee a user-friendly app with multiple features that can make your customer communication seamless. Vimerse InfoTech has specialization in creating cutting-edge mobile apps for diverse industries.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/telecommunication.svg' alt="Telecom Software Development" width="60px" />
								</div>
								<h3>Telecom Software Development</h3>
								<p>Revolutionize your communication game with our high-performance telecom software development services. Our team of experts specializes in delivering cutting-edge software by keeping all business needs on top priority.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Take your telecom business to new horizons with our top-notch IT solutions. From advanced network management to robust security systems.</h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Get It Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>The Benefits of Telecom Solutions By Vimerse InfoTech Inc</h2>
						<p>Client satisfaction is always our top priority, and it fuels us to deliver the best solutions. Here are some key benefits that you will have from us.</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Affordability</h3>
								<p>We used to deliver affordable solutions, including high quality, so the clients must find it fantastic as per their budget. Our telecom solutions add value to the businesses.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Security</h3>
								<p>Before delivering the solutions, we test them until it breaks, then we improve and test them again to ensure that you have a robust and fully secure solution.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Experienced Resource</h3>
								<p>We have a strong network of trained professionals, software developers, mobile app developers, and designers who can assist you with all your business needs.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Scalable Solutions</h3>
								<p>The Telecom industry is dynamically progressing, so if you are involved in the telecom business, we can help you achieve the scalability you desire.</p>
							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);
}

export default Telecommunications
