import React from 'react';
import { Helmet } from 'react-helmet';
import ClientLogos from '../../components/ClientLogos/ClientLogos';
import SuccessStory from '../../components/SuccessStory/SuccessStory';

const Career = () => {
	return (
		<>
			<Helmet>
				<title>Jobs & Career | Vimerse InfoTech</title>
				<meta name="description" content="Join Vimerse InfoTech: Grow your career with cutting-edge tech and a skilled team. Elevate your potential today! 🚀 #ITJobs #TechCareer" />
				<link rel="canonical" href="https://vimerseit.com/career" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<div className='subtitle text-red'>Jobs & Career</div>
									<h1>Career Catch-Ups With Vimerse</h1>
								</div>
								<p>Drive your career in the right direction where you can grow sustainably.</p>
								<p>
									Are you looking for an organization where you can have a healthy work-life balance and may grow professionally? Then Vimerse InfoTech Inc is the right place that you are looking for. Working with us is like being part of a community of professionals where your talent, opinion, and skills will be respected. As a responsible employer, we provide a positive environment for all professionals working with us.
								</p>
								<p>
									Have a flexible office routine, enjoy working with your teammates, and experience a vibrant workplace that you have never experienced before.
								</p>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/career-banner.jpg" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section className='repeat-section bg-f2f2f2 our-approch-section'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='row'>
								<div className='col-lg-6 mt-0 mt-lg-5'>
									<div className='row'>
										<div className='col-lg-12 col-md-6'>
											<div className='service-item'>
												<h3>Jobseekers</h3>
												<p>
													Jobseekers are people who are looking for employment opportunities and use various methods to find job openings.
												</p>
											</div>
										</div>
										<div className='col-lg-12 col-md-6'>
											<div className='service-item'>
												<h3>Employees</h3>
												<p>
													Employees are hired by employers to work for compensation, either full-time or part-time, and can be permanent, temporary, or contract workers.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-6'>
									<div className='row'>
										<div className='col-lg-12 col-md-6'>
											<div className='service-item'>
												<h3>Clients</h3>
												<p>
													Clients are individuals or organizations that pay for goods or services provided by a business or professional to fulfill a need or desire.
												</p>
											</div>
										</div>
										<div className='col-lg-12 col-md-6'>
											<div className='service-item'>
												<h3>Entrepreneur</h3>
												<p>
													Entrepreneurs start and run businesses to make a profit by identifying market needs and developing solutions. They must be risk-takers, quick decision-makers, and possess strong leadership and management skills.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-6 mt-5 mt-lg-0'>
							<div className='section-title text-left max-width-unset'>
								<h2>The Law of Attraction: What You Seek is Seeking You</h2>
								<p>
									The Law of Attraction has its roots in various spiritual and philosophical traditions, including the New Thought movement and the teachings of authors such as Napoleon Hill and Rhonda Byrne. The concept gained mainstream popularity in the early 2000s after the release of the book and documentary film, "The Secret."
								</p>
								<p>The idea is that when a person focuses their thoughts and energy on positive things, they will attract positive experiences and outcomes into their life. Conversely, if a person focuses on negative thoughts, they will attract negative experiences.</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{/* <section className='repeat-section bg-f2f2f2'>
				<div className='container'>
					<div className='section-title max-width-unset'>
						<h2 className=''>We Are Hiring Heroes! Time To Value Your Skills</h2>
					</div>
					<div className='row'>
						<div className='col-12 text-center'>
							<p>We at Vimerse InfoTech Inc are looking for talented professionals who are dedicated to their work and delivering high-grade professionalism while creating solutions that can help our clients to solve their business challenges effortlessly. With a track record of 100% success over 300+ projects, Vimerse InfoTech Inc is an amazing platform for showcasing and elevating your skills. We look at every professional as a hero who takes ownership and is dedicated to their work with a positive mindset. We hire candidates who are team players, so their colleagues must enjoy their presence with them. Most workplaces are client-centric, but we are employee and client-centric, where we value both. If you have it, then please send your resume to hr@vimerseit.com. Take the first step towards a satisfying career with Vimerse InfoTech Inc and allow your abilities to develop in a dynamic atmosphere.
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<div className="job-list row">
								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Database Developer</h3>
										<p>No of positions- 4 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Database Developer posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Customer Support</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Customer Support posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Java Backend Developer</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Java Backend Developer posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Executive Assistant</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Executive Assistant posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Technical Writer</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Technical Writer posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>IT Recruiter</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and IT Recruiter posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Oracle HCM</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Oracle HCM posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>

								<div className='col-md-6 d-flex'>
									<div className='job-item'>
										<h3>Deskside/Desktop Support</h3>
										<p>No of positions- 2 <span>Location : Jaipur, Rajasthan</span></p>
										<div className='details'>
											Hello, there we are looking for expanding our team and Deskside/Desktop Support posts are available for you.
										</div>
										<Link to="/submit-resume" className="default-btn btn-primary mt-5">
											Apply Now <img src="../images/icons/apply-now.svg" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			<div id="example-widget-container"></div>



			<section className='repeat-section pb-0'>
				<div className='container'>
					<div className='section-title'>
						<h2>Your First Step of Journey With Us</h2>
						<p>We have grown ourselves as a workplace where all professionals must have equal growth opportunities.</p>
						<p>While hiring professionals, skills, domain expertise, and a solution-oriented mindset is our priority. Our recruitment process is comprised of three steps as follows:</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>01</span>
								</div>
								<div className="hire-box w-100">
									<h3>Interview</h3>
									<p>It will begin with a formal round where we will introduce ourselves to each other. At this round, we will assess your basics and technical skills related to your domain.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>02</span>
								</div>
								<div className="hire-box w-100">
									<h3>Technical Round</h3>
									<p>The second round will be technical,  where our panel of experts will judge your core technical skills in greater depth to determine your genuine potential. </p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 d-flex">
							<div className="hire-process-box">
								<div className="process-count">
									<span>03</span>
								</div>
								<div className="hire-box w-100">
									<h3>Practical Round</h3>
									<p>It is the final and crucial round, where we will test your technical skills and problem-solving ability by giving you a live problem statement including all details.</p>
								</div>
							</div>
						</div>
					</div>

					<div className='row mt-5'>
						<div className='col-12 text-center'>
							<h3>
								Once you clear all the rounds, we will welcome you as our team member.
							</h3>
						</div>
					</div>
				</div>
			</section >

			<SuccessStory />

			<ClientLogos />
		</>
	);
}

export default Career
