import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const RpoServices = () => {
	return (
		<>
			<Helmet>
				<title>RPO Services | Vimerse InfoTech</title>
				<meta name="description" content="Get RPO services from industry professionals and enhance your recruitment process with reduced costs. Vimerse InfoTech ensures seamless customized RPO services." />
				<link rel="canonical" href="https://vimerseit.com/rpo-services" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>RPO Services</h1>
								</div>
								<p>Get recruitment process outsourcing services for all recruitment needs.</p>
								<p>
									We offer outsourced recruitment services tailored to every business and objective. Vimerse InfoTech Inc’s RPO solutions are designed to support clients in finding desired talent for the work. Our network of RPO professionals will support you throughout the talent acquisition lifecycle, where you only need to disclose your needs; we will take care of the rest. We will assist you in scaling your team hassle-free and overcoming the challenges.White finding human resources we can assist you in amplifying the quality of your recruitment process.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Discuss & Onboard Best Talent!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/rpo-services-banner.jpg" alt="RPO Services" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Redefine Talent Acquisition With <br className='d-none d-lg-block' /> Vimerse InfoTech Inc</h2>
						<p>
							For every business, talent acquisition needs are different; by considering it, we offer you a wide range of solutions under our RPO services. Whether it is hiring an individual or a team, we can suggest and provide the best solution based on our experience. Vimerse InfoTech helps businesses, companies, and organizations find niche-based professionals, including improving recruitment. Our RPO services are the best for finding talent within the boundaries or hiring global talents for specific roles.
						</p>
						<p>Vimerse RPO Services include:</p>
					</div>
					<div className='row'>
						<div className='col-lg-4'>
							<div className='flip-box'>
								<div className='flip-box-inner'>
									<div className='flip-box-front'>
										<img src="../images/on-demand-rpo-services.svg" alt="On-Demand RPO Services" className='w-100' />
										<h3>On-Demand RPO Services</h3>
									</div>
									<div className="flip-box-back">
										<p>On-demand, RPO services help ramp the hiring efforts quickly where you can have experienced recruiters to hire professionals on a need basis. You can also opt to hire a team of professionals on a project basis. There is no overhead cost associated, which makes it cost-effective.</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='flip-box'>
								<div className='flip-box-inner'>
									<div className='flip-box-front'>
										<img src="../images/partial-cycle-rpo.svg" alt="Partial-Cycle RPO" className='w-100' />
										<h3>Partial-Cycle RPO</h3>
									</div>
									<div className="flip-box-back">
										<p>Suppose you need to outsource certain components of recruitment, such as screening, sourcing candidates, and conducting interviews, or hire professionals for niche skill sets. In that case, Vimerse InfoTech Inc can help you with Partial-Cycle RPO services.</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col-lg-4'>
							<div className='flip-box'>
								<div className='flip-box-inner'>
									<div className='flip-box-front'>
										<img src="../images/full-cycle-rpo.svg" alt="Full-Cycle RPO" className='w-100' />
										<h3>Full-Cycle RPO</h3>
									</div>
									<div className="flip-box-back">
										<p>Our Full-cycle RPO services are designed for strategic consultation and take care of your whole recruitment process, including identifying the loopholes, strategy for improvement, and planning for future talent acquisition programs. Here we can deliver you optimal results.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Streamline Your Recruitment Process With Result Driven RPO Services</h2>
						<p>We assist you in making your recruitment process smooth and driving results from it with the help of RPO services and solutions. Here are some advantages that Vimerse InfoTech ensures.</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/enhanced-recruiting-efficiency.svg' alt="Enhanced Recruiting Efficiency" width="60px" />
								</div>
								<h3>Enhanced Recruiting Efficiency</h3>
								<p>Our RPO services are well-tested in enhancing recruitment efficiency and amplifying the hiring results for an organization. As a result, the HR department can work with super flexibility while hunting for potential talent. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/time.svg' alt="Cost & Time Saving" width="60px" />
								</div>
								<h3>Cost & Time Saving</h3>
								<p>Availing of RPO service is cost & time-saving as it saves you from the overhead recruitment costs such as ads, screening the candidates, interviewing them, arrangements for interviews, and more. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/users.svg' alt="Easy Access To the Talent Pool" width="60px" />
								</div>
								<h3>Easy Access To the Talent Pool</h3>
								<p>Our network of RPO professionals is skilled and experienced. They know how to find and engage the talent. They will make available the best talent for your company or organization whom you can onboard to meet your business objectives. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/scalability.svg' alt="Scalability" width="60px" />
								</div>
								<h3>Scalability</h3>
								<p>The overall goal of RPO services is to make the recruitment process easy, and at the end of the day, all vacancies must be filled. The best part is that based on our requirements, we can expand or decrease the team of our recruiters to provide you with the best results.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/compliance.svg' alt="Compliance Regulations" width="60px" />
								</div>
								<h3>Compliance Regulations</h3>
								<p>As Vimerse InfoTech Inc is one of the leading RPO Services providers, we have extensive knowledge of compliances, labor laws, and recruiting standards. So, while hiring the candidate, there will be complete adherence to the laws and regulations.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analytics.svg' alt="Analytics & Reporting" width="60px" />
								</div>
								<h3>Analytics & Reporting</h3>
								<p>RPO allows for accessing real-time data and reporting. As a service provider, we track and record data and activities at each level for real-time reporting and generating insights to predict future talent needs and make recruitment easier but more powerful. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default RpoServices
