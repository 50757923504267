import React from 'react';
import { Link } from 'react-router-dom';

const BlogPostCardBlogPage = ({ post_url, post_image, title }) => {
    return (
        <>
            <div className="col-md-6">
                <Link className="single-blog-post" to={post_url} >
                    <div className="post-image">
                        <img src={post_image} alt="title" />
                    </div>

                    <div className="post-content">
                        <h3>
                            {title}
                        </h3>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default BlogPostCardBlogPage;