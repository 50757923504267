import React, { useState } from 'react';
import './GoTop.scss';
const GoTop = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div className="back-to-top">
                <div className='top' onClick={scrollToTop} style={{ display: visible ? 'flex' : 'none' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.487" height="7.149" viewBox="0 0 12.487 7.149">
                        <path fill='Currentcolor' d="M12.616,1.992,7.081,7.544a.574.574,0,0,1-.214.14.756.756,0,0,1-.494,0,.574.574,0,0,1-.214-.14L.606,1.992a.782.782,0,0,1-.231-.577A.809.809,0,0,1,.622.822a.8.8,0,0,1,1.153,0L6.619,5.666,11.463.822a.772.772,0,0,1,.568-.231.8.8,0,0,1,.585.247.8.8,0,0,1,0,1.153Z" transform="translate(12.861 7.726) rotate(180)" />
                    </svg>
                </div>
            </div>
        </>
    )
}

export default GoTop;