import React, { useEffect, useState } from 'react';
import "./Blog.scss";
import BlogPostCardBlogPage from './BlogPostCardBlogPage';
const BlogPagePostList = () => {

    const [width, setWidth] = useState(0);
    useEffect(() => {
        const handleWindowSizeChange = () => setWidth(window.innerWidth);
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => window.removeEventListener("resize", handleWindowSizeChange);
    }, [width]);

    return (
        <>

            <BlogPostCardBlogPage post_url="/blog/what-is-staff-augmentation-&-what-are-its-benefits" post_image="../images/blog/blog-1.jpg" title="What is Staff Augmentation & What Are Its Benefits" />
            <BlogPostCardBlogPage post_url="/blog/the-benefits-of-implementing-salesforce-in-your-business" post_image="../images/blog/the-benefits-implementing-salesforce-in-your-business.jpg" title="The Benefits of Implementing Salesforce in Your Business" />
            <BlogPostCardBlogPage post_url="/blog/tips-and-tricks-to-develop-a-professional-website" post_image="../images/blog/tips-and-tricks-to-develop-a-professional-website.jpg" title="Tips and Tricks To Develop A Professional Website" />
            <BlogPostCardBlogPage post_url="/blog/key-steps-in-mobile-app-development-from-idea-to-launch" post_image="../images/blog/key-steps-in-mobile-app-development-from-idea-to-launch.jpg" title="Key Steps in Mobile App Development: From Idea to Launch" />
        </>
    )
}

export default BlogPagePostList;