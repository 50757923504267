import React from "react";
import Slider from "react-slick";
import './Portfollio.scss';

var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                // dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 991,
            settings: {
                dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                nav: false,
                navText: false,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                nav: false,
                navText: false,
            }
        }
    ]
};
const Portfollio = ({ customClass }) => {
    return (
        <>
            <section className={`portfollio-section ${customClass}`}>
                <div className="container">
                    <div className="section-title ">
                        <h2>Our Portfolio</h2>
                        <p>
                            Here are some of our projects that we have successfully delivered to our clients and help them to execute it to meet objectives.
                        </p>
                    </div>
                    <Slider className="portfolio-slider testimonail-slider" {...settings}>
                        <div className="slide-bx">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-image">
                                        <img src='../images/portfolio/portfolio-1.png' alt="Shri Govind Guru Government College, Banswara" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-content">
                                        <h3>Shri Govind Guru Government College, Banswara</h3>
                                        <div className="project-category">
                                            Govt./ Education Project
                                        </div>
                                        <p>
                                            We have successfully designed, developed, and deployed a college management system for  Shri Govind Guru Government College, Banswara, where it helps the administration, college staff and students with their needs. Its key highlights are staff management, result management, student management,  reporting, student-teacher communication, and more.
                                        </p>
                                        {/* <div className="mt-5">
                                            <Link to="/contact" className="default-btn">
                                                Read More <span></span>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide-bx">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-image">
                                        <img src='../images/portfolio/balakdaji-portfolio.png' alt="Shree Balakdas Ji Maharaj" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-content">
                                        <h3>Shree Balakdas Ji Maharaj</h3>
                                        <div className="project-category">
                                            Corporate Social Responsibility
                                        </div>
                                        <p>
                                            Vimerse InfoTech is committed to his CSR responsibilities, so we have created an informational website for Baba Balakdas Ji Maharaj under it. The website provides complete information regarding his devotees' spiritual and social activities. He is a great spiritual Guru and guides many people to a well-balanced, spiritual, and happy life. He also motivates the people and devotees to serve this world as God is everywhere and in every living being.
                                        </p>
                                        {/* <div className="mt-5">
                                            <Link to="/" className="default-btn">
                                                Read More <span></span>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide-bx">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-image">
                                        <img src='../images/portfolio/dhruv-hospital-portfolio.png' alt="Shri Dhruv Charitable Hospital" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <div className="portfolio-content">
                                        <h3>Shri Dhruv Charitable Hospital</h3>
                                        <div className="project-category">
                                            Healthcare
                                        </div>
                                        <p>
                                            Shri Dhruv Bhakti Seva Ashram Trust manages Shri Dhruv Charitable Hospital. The motive behind the hospital is to provide treatment as a noble service and make it affordable for all. Here we have also participated in the noble cause and played our part by developing a responsive website to help the management to make all information available with a click.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

            </section>
        </>
    );
}

export default Portfollio