import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import { Helmet } from 'react-helmet';

const SalesforceConsultingServices = () => {
	return (
		<>
			<Helmet>
				<title>Salesforce Consulting Services | Vimerse InfoTech</title>
				<meta name="description" content="Being a leading Salesforce consulting firm, we help businesses to achieve goals. Our range of services covers Salesforce implementation, customization, and more." />
				<link rel="canonical" href="https://vimerseit.com/salesforce-consulting-services" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Salesforce Consulting Services</h1>
								</div>
								<p>
									Transform your business for the future with the Salesforce consulting services by experts.
								</p>
								<p>Take your sales and marketing game to the next level with Vimerse InfoTech Inc’s Salesforce Consulting services. Our team of experienced Salesforce consultants will help you create robust solutions customized to your business needs, including multiple advantages. Our range of solutions helps businesses, enterprises, and organizations to quickly and seamlessly increase customer engagement, drive more sales, and grow brands in the market. </p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Consult With Experts Now!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/salesforce-consulting-services-banner.jpg" alt="Salesforce Consulting Services" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Range of Salesforce Consulting Services For Every Need</h2>
						<p>Unlock the potential of your business with Salesforce Consulting today.</p>
						<p>If you are looking for effective Salesforce Consulting Services, then Vimerse InfoTech Inc offers you a range of services that you can choose to align your enterprise needs. Our services include:</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Salesforce consulting" width="60px" />
								</div>
								<h3>Salesforce consulting</h3>
								<p>Get the best assistance and solutions from experienced Salesforce Consultants who have helped multiple organizations to be future-ready with Salesforce technology.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/implementation.svg' alt="Salesforce Implementation" width="60px" />
								</div>
								<h3>Salesforce Implementation</h3>
								<p>Whether it is sales cloud, marketing cloud, service cloud, or anything you need to implement with Salesforce, we will make it happen by keeping all your goals in mind.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/integration2.svg' alt="Salesforce Integration" width="60px" />
								</div>
								<h3>Salesforce Integration</h3>
								<p>Integrate Salesforce into your existing business practices and leverage it for your business. Our team of experienced professionals will assist you in implementing it as you need.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/web-development.svg' alt="Salesforce Development Services" width="60px" />
								</div>
								<h3>Salesforce Development Services</h3>
								<p>Customization can help a lot if you need something trendy more than traditional systems. With us, you will have the best experience in Salesforce development services.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/migration2.svg' alt="Salesforce Migration Services" width="60px" />
								</div>
								<h3>Salesforce Migration Services</h3>
								<p>Migrating to the Salesforce Cloud with the experts is such as good investment. Here Vimerse InfoTech Inc offers you Salesforce Migration Services that drive value to the business.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/cloud.svg' alt="Salesforce Re-engineering" width="60px" />
								</div>
								<h3>Salesforce Re-engineering</h3>
								<p>Want to update or modify your existing Salesforce Cloud Solution? We can help with it at our best level. Our team will assess your existing system, create a perfect strategy, and implement it.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/content-writer.svg' alt="Salesforce Audit" width="60px" />
								</div>
								<h3>Salesforce Audit</h3>
								<p> Audit your existing Salesforce system with experts to review the security and identify the risks and required upgrades. Our professionals will help you to mitigate the risks by leveraging their expertise.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/managed-service.svg' alt="Salesforce Managed Services" width="60px" />
								</div>
								<h3>Salesforce Managed Services</h3>
								<p>Manage the Salesforce environment with a set of managed services like administration, support, development, and security. It will help you to free up internal resources, improve efficiency, and more.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/support-maintenance.svg' alt="Support & Maintenance" width="60px" />
								</div>
								<h3>Support & Maintenance</h3>
								<p>Get 24/7 support from experienced professionals for your Salesforce Cloud. Our team is skilled in handling all the issues that come with runtime or any other. Contact us anytime.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section why-choose bg-f2f2f2'>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-7">
							<div className="section-title text-left mw-100">
								<h2>Why Vimerse InfoTech Inc For Salesforce Consulting?</h2>
								<p>Our Salesforce Consulting services can help you with tailored solutions for businesses of any size; our expert consultants can quickly develop and implement powerful strategies to help you optimize your sales process and maximize efficiency. With us, you will have </p>
							</div>
							<ul className="list-design mt-4">
								<li>Experienced Salesforce Consultants</li>
								<li>Wide Range of Salesforce Services</li>
								<li>Effective Mapping of Objectives with Salesforce Services</li>
								<li>Suitable Salesforce Solutions recommendations and more.</li>
							</ul>
							<p className="mt-4">Our expertise allows us to be a first preference for Salesforce consultation.</p>
						</div>
						<div className="col-lg-5">
							<div className="image-radius-design">
								<img src='../images/why-choose.jpg' alt="Tailored IT Solutions That Drives Real-Time Results" className='img-fluid' />
							</div>
						</div>
					</div>
				</div>

			</section>

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default SalesforceConsultingServices
