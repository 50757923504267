import React from "react";
import { Link } from "react-router-dom";
import "./MainBanner.scss";

const MainBanner = () => {

    return (
        <>
            {/* <div className="main-banner main-banner-one">
                <div className="container-fluid">
                    <Slider {...settings}>
                        <div className="slide-bx">
                            <div className="row">
                                <div className="col-lg-5 col-md-12">
                                    <div className="main-banner-content">
                                        <div className="d-table">
                                            <div className="d-table-cell">
                                                <div className="content">
                                                    <div className="subtitle text-red">Welcome To Consulter Consultancy</div>
                                                    <h1 className="text-white">Transform Your Business with Innovative IT Solutions</h1>
                                                    <p className="text-white">At Vimerse, we are committed to providing cutting-edge IT solutions that help businesses of all sizes achieve their goals. Our team of experts specializes in everything from software development and cloud computing to cybersecurity and digital marketing. Whether you need a custom application or a comprehensive IT strategy, we have the skills and expertise to deliver results.</p>

                                                    <Link to="/contact" className="default-btn">
                                                        Start Developing Right Solution!
                                                        <span></span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12 d-none d-lg-block">
                                    <div className="home-banner">
                                        <img src="../images/main-banner.jpg" alt="image" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="slide-bx">
                            <div className="row">
                                <div className="col-lg-5 col-md-12">
                                    <div className="main-banner-content">
                                        <div className="d-table">
                                            <div className="d-table-cell">
                                                <div className="content">
                                                    <div className="subtitle text-red">Welcome To Vimerse InfoTech Inc.</div>
                                                    <h1 className="text-white">Empowering Your Business for a Digital Tomorrow</h1>
                                                    <p className="text-white">At Vimerse, we are dedicated to empowering businesses for the digital future by providing innovative IT solutions that enhance productivity, streamline processes, and drive growth. Our team of experts has the knowledge and expertise to help you stay ahead of the curve and capitalize on emerging trends in the digital landscape. From software development and cloud computing to cybersecurity and digital marketing, we are committed to delivering solutions that transform businesses and drive results.</p>
                                                    <Link to="/contact" className="default-btn">
                                                        Start Developing Right Solution!
                                                        <span></span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12 d-none d-lg-block">
                                    <div className="home-banner">
                                        <img src="../images/main-banner-2.png" alt="image" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div> */}

            <div className="home-page-video">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-text">
                                <div className="subtitle text-white">Harness The Power Of Technology With Industry Experts </div>
                                <h1 className="text-white">Next-gen IT Solutions For All Business Needs</h1>

                                <Link to="/contact" className="default-btn">
                                    Connect & Get It Now!
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <video autoPlay loop playsInline className="home-video" poster="../images/video-thumbnail-bg.jpg">
                    <source src="../images/video.mp4" type="video/mp4" />
                </video>
            </div>
        </>
    );
}

export default MainBanner