import React from 'react';
import { Link } from 'react-router-dom';
import ClientTestiomnails from '../../components/ClientTestiomnails/ClientTestiomnails';
import Portfollio from '../../components/Portfollio/Portfollio';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import { Helmet } from 'react-helmet';

const DataScienceConsulting = () => {
	return (
		<>
			<Helmet>
				<title>Data Science Consulting | Vimerse InfoTech</title>
				<meta name="description" content="Get perfect data science consulting and implementation services from skilled professionals. Solve your business challenges with data-driven insights." />
				<link rel="canonical" href="https://vimerseit.com/data-science-consulting" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Data Science Consulting</h1>
								</div>
								<p>
									Unlock the potential of your business data with high-quality data science consulting services.
								</p>
								<p>We provide innovative data-driven solutions that help organizations make better decisions, increase efficiency, and uncover hidden opportunities. Our talented team of data consultants will analyze your data and develop strategies to help you achieve your business goals. They will help you to have a customized data solution to meet all your requirements. </p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let’s Unlock Your Data’s Power!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/data-science-consulting-banner.jpg" alt="Data Science Consulting" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section'>
				<div className='container'>
					<div className='section-title'>
						<h2>Data Science Consulting Services & Solutions By Vimerse InfoTech Inc</h2>
						<p>The right expertise can make data more powerful, and Vimerse InfoTech is here to make it possible for you. We have a team of data science professionals with extensive experience providing consulting services and solutions to diverse industries. Our data science consulting services and solutions include: </p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Data Science Consulting" width="60px" />
								</div>
								<h3>Data Science Consulting</h3>
								<p>Get high-quality data science consulting services from industry experts who will help you to make your data work for future growth. We provide end-to-end consultation that makes a business grow.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/modelling.svg' alt="Data Modernization" width="60px" />
								</div>
								<h3>Data Modernization</h3>
								<p>Modernize your existing data system using Cloud technologies with Vimerse InfoTech Inc. We assist the business in leveraging the power of data using cloud services AWS, Adobe, and many more.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/implementation.svg' alt="Data Science Solution Implementation" width="60px" />
								</div>
								<h3>Data Science Solution Implementation</h3>
								<p>Our data science experts have extensive experience implementing customized data solutions for all businesses, from startups to enterprises. So, get it as per your requirements.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/data.svg' alt="Data Management" width="60px" />
								</div>
								<h3>Data Management</h3>
								<p>Managing data is one of the biggest challenges for every business, so here, we can assist you in managing it best with our data science expertise. With us, you will have the best experience of it.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/adaptability.svg' alt="AI & ML Solutions" width="60px" />
								</div>
								<h3>AI & ML Solutions</h3>
								<p>Leverage the power of AI & ML for your business data. We can help you automate the data science lifecycle process from preparation to implementing of an AI-based data management system.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/data1.svg' alt="Data Architecture" width="60px" />
								</div>
								<h3>Data Architecture</h3>
								<p>Our team of experts will help you identify patterns and trends in your data and unlock the full potential of your business. Get the best experience in Data Architecture implementation with Vimerse InfoTech Inc. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f2f2f2'>
				<div className='container'>
					<div className='section-title'>
						<h2>Our Appraoch That Makes Us Different</h2>
						<p>We follow a unique process to provide fine-quality data science solutions to businesses. It includes:</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/search.svg' alt="Research" width="60px" />
								</div>
								<h3>Research</h3>
								<p>First, we study the existing data system of our clients and then perform extensive research to create the perfect roadmap.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analysis.svg' alt="Requirement Analysis" width="60px" />
								</div>
								<h3>Requirement Analysis</h3>
								<p>Our team of experts will analyze your business data needs to find the best way to make a seamless data science strategy.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/modelling.svg' alt="Data Modelling" width="60px" />
								</div>
								<h3>Data Modelling</h3>
								<p>The next crucial step is data modelling. First, we will prepare your data and then implement the model that provides accurate results.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/solution-hand.svg' alt="Solution" width="60px" />
								</div>
								<h3>Solution</h3>
								<p>In this step, we will create the solutions by keeping all needs in your mind using our AI & Machine Learning, Data Science, and coding expertise.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/testing.svg' alt="Testing" width="60px" />
								</div>
								<h3>Testing</h3>
								<p>At this step, we will test the solution, including the data model, to eliminate all kinds of risks, make your data system secure, and deliver it to you.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/support-maintenance.svg' alt="Support & Maintenance" width="60px" />
								</div>
								<h3>Support & Maintenance</h3>
								<p>With us, you will have the best support & maintenance services. We are ready to assist you round the clock in case of any issues.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<WhyChoose />

			<Portfollio customClass="bg-f3f7ff" />

			<ClientTestiomnails />
		</>
	);
}

export default DataScienceConsulting
