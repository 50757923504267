import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRight } from 'react-icons/ai';

const CommanServices = () => {
    return (
        <>
            <section className='repeat-section services-section'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>Our Services</h2>
                        <p>Experienced consultants delivering on a broad set of tech requirements.</p>
                    </div>

                    <div className='row'>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/staffing.svg' alt="Strategic Staffing" />
                                </div>
                                <h3>Strategic Staffing</h3>
                                <p>Optimize your workforce with strategic staffing and onboard skilled staff in less time. </p>
                                <div className='readmore-btn'>
                                    <Link to="/strategic-staffing" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/staff-augmentation.svg' alt="Staff Augmentation" />
                                </div>
                                <h3>Staff Augmentation</h3>
                                <p>Leverage external talent for specific needs and have better human resource management.</p>
                                <div className='readmore-btn'>
                                    <Link to="/staff-augmentation" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/rpo.svg' alt="RPO Services" />
                                </div>
                                <h3>RPO Services</h3>
                                <p>Streamline your hiring process with RPO services and save time hiring the right talent.</p>
                                <div className='readmore-btn'>
                                    <Link to="/rpo-services" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/web-development.svg' alt="Software Development" />
                                </div>
                                <h3>Software Development </h3>
                                <p>We will be with you from the development to the deployment of a custom software.</p>
                                <div className='readmore-btn'>
                                    <Link to="/software-development" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/app-development.svg' alt="Mobile App Development" />
                                </div>
                                <h3>Mobile App Development</h3>
                                <p>Get result-driven mobile apps for your business from experienced mobile app developers. </p>
                                <div className='readmore-btn'>
                                    <Link to="/mobile-app-development" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/web-development2.svg' alt="Web Design & Development" />
                                </div>
                                <h3>Web Design & Development</h3>
                                <p>Our specialty is responsive web design and robust web development, so leverage it for your business website. </p>
                                <div className='readmore-btn'>
                                    <Link to="/website-design-and-development" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/payroll.svg'/>
                                </div>
                                <h3>Accounting & Payroll Solutions</h3>
                                <p>We work exactly in such a way that while providing accounting and payroll services for your needs.</p>
                                <div className='readmore-btn'>
                                    <Link to="/accounting-and-payroll-solutions" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/marketing.svg' alt="Digital Marketing & Brand Imaging" />
                                </div>
                                <h3>Digital Marketing & Brand Imaging</h3>
                                <p>The right digital marketing strategy can help you have the brand image you expect for your business.</p>
                                <div className='readmore-btn'>
                                    <Link to="/digital-marketing-and-brand-imaging" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/ui-ux.svg'/>
                                </div>
                                <h3>Website Design</h3>
                                <p>We provide B2B and B2C portal with all the needed interfaces taking tour, travel and online business.</p>
                                <div className='readmore-btn'>
                                    <Link to="/website-design" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/management.svg' alt="Project Management" />
                                </div>
                                <h3>Project Management</h3>
                                <p>Get project management services from professionals having domain expertise and save your time & cost-effectively.</p>
                                <div className='readmore-btn'>
                                    <Link to="/project-management" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/oracle-hcm.svg' alt="Oracle HCM" />
                                </div>
                                <h3>Oracle HCM</h3>
                                <p>Manage your workforce effectively with Oracle HCM. We will assist you in implementing it right.</p>
                                <div className='readmore-btn'>
                                    <Link to="/oracle-hcm" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/aws.svg' alt="Amazon Web Services (AWS)" />
                                </div>
                                <h3>Amazon Web Services (AWS)</h3>
                                <p>We help businesses to migrate to Amazon Web Services in no time. Our experts ensure it is seamless.</p>
                                <div className='readmore-btn'>
                                    <Link to="/amazon-web-services" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/cloud.svg' />
                                </div>
                                <h3>Cloud Services</h3>
                                <p>Leverage Cloud technologies for your business with flexibility and scalability, including cost-effectiveness. </p>
                                <div className='readmore-btn'>
                                    <Link to="/cloud-services" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/salesforce.svg' alt="Salesforce Consulting Service" />
                                </div>
                                <h3>Salesforce Consulting Services</h3>
                                <p>Transform your existing CRM practices into powered ones with Salesforce consulting services. </p>
                                <div className='readmore-btn'>
                                    <Link to="/salesforce-consulting-services" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/consulting.svg' alt="Data Science Consulting" />
                                </div>
                                <h3>Data Science Consulting</h3>
                                <p>Implement the best Data Science practices and manage all your business data with Vimerse InfoTech.</p>
                                <div className='readmore-btn'>
                                    <Link to="/data-science-consulting" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/management.svg'/>
                                </div>
                                <h3>Salesforce Consulting Services</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className='readmore-btn'>
                                    <Link to="/salesforce-consulting-services" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-3 col-lg-6 col-md-6 d-flex'>
                            <div className='services-bx'>
                                <div className='icon'>
                                    <img src='../images/icons/management.svg'/>
                                </div>
                                <h3>Data Science Consulting</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className='readmore-btn'>
                                    <Link to="/data-science-consulting" className='text-link'>
                                        Read More
                                        <AiOutlineRight />
                                    </Link>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
        </>
    );
}

export default CommanServices