import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MediaEntertainment = () => {
	return (
		<>
			<Helmet>
				<title>Media & Entertainment | Vimerse InfoTech</title>
				<meta name="description" content="Vimerse InfoTech Inc provides comprehensive IT services for media and entertainment companies. Our team of professionals ensure high-quality solutions to meet all entertainment business needs." />
				<link rel="canonical" href="https://vimerseit.com/industries/media-and-entertainment" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Media & Entertainment</h1>
								</div>
								<p>
									Take your viewers' experience to a new level with media and entertainment solutions by Vimerse InfoTech Inc.
								</p>
								<p>
									The methods of entertainment have been changed, and technology is playing a key role in it. Now, multiple OTT, video streaming, live streaming, and music streaming apps are available, and people are using them as their choice. If you want to launch a website, app or platform for your media & entertainment business, we can assist you with the right solutions. Our solutions will help you to gain a competitive edge in ongoing competition in the media & entertainment industry.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Let's Turn Your Idea Into Reality!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/media-entertainment.jpg" alt='Media & Entertainment' className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>Innovative Solutions For Media & Entertainment Brands</h2>
						<p>Vimerse InfoTech Inc offers a complete range of IT solutions for the media & entertainment industry. Our range of solutions covers the following:
						</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/consulting.svg' alt="Media & Entertainment Consulting" width="60px" />
								</div>
								<h3>Media & Entertainment Consulting</h3>
								<p>We can provide you with a team of experienced professionals who will help you to find out what kind of solutions can help you to excel with your business. They assist media businesses to thrive.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/app-development.svg' alt="Mobile App Development" width="60px" />
								</div>
								<h3>Mobile App Development</h3>
								<p>Mobile apps are helping content creators to reach their desired audience base. We can provide end-to-end mobile app solutions like OTT apps, live streaming apps, and more.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/web-development.svg' alt="Web Development" width="60px" />
								</div>
								<h3>Web Development</h3>
								<p>Are you looking to develop a professional website for your entertainment business? Look no further; Vimerse InfoTech Inc is an appropriate choice for it. Our skilled web developers will transform your idea into a media website.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/content-writer.svg' alt="Content Creation & Production" width="60px" />
								</div>
								<h3>Content Creation & Production</h3>
								<p>Content creation plays a crucial role in the entertainment industry, and what if you have AI/ML or the latest technology-based tools to create the content as you wish? We provide modern content tools based on the latest technologies. </p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/analytics.svg' alt="Audience Analytics" width="60px" />
								</div>
								<h3>Audience Analytics</h3>
								<p>An analytics system can help you analyze what your audience wants, what they prefer to watch, and more. We can provide solutions that can help you gain valuable insights.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/solutions3.svg' alt="Managed IT Services" width="60px" />
								</div>
								<h3>Managed IT Services</h3>
								<p>Outsource all your IT needs and focus on your core operations instead of managing the resources. Have the best experience in managed IT services from Vimerse InfoTech Inc. </p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Revolutionize your entertainment and media delivery practices with innovative & scalable solutions. </h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Get It Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Media & Entertainment Solutions With Real-time Benefits</h2>
						<p>Unleash the hidden potential of your business and supercharge the entertainment game to reach a wider audience. Our solutions come with the following benefits: </p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Expanded Reach</h3>
								<p>Our solutions help you to reach the potential customer base where you can offer your services with the best customer experience. It will directly help you to generate more revenue.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Personalized Experience</h3>
								<p>A personalized experience can help you create the best delivery effect for all your media & entertainment services. Vimerse InfoTech Inc has expertise in providing apps or platforms that offer personalized experiences.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Scalable Infrastructure</h3>
								<p>Scalability is the first thing we keep in mind to develop solutions. Our entertainment solutions remain seamless from end-to-end, i.e., managing high traffic to streamlined content delivery across all channels. </p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Data Security</h3>
								<p>With our solutions, the data of your customers will be safe and secure. Our skilled professionals will ensure secure solutions, including meeting all your data needs and the privacy of the customers.</p>
							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);
}

export default MediaEntertainment
