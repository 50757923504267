import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EducationElearning = () => {
	return (
		<>
			<Helmet>
				<title>Education & E-learning | Vimerse InfoTech</title>
				<meta name="description" content="Get custom elearning solutions that make the education process seamless. Vimerse InfoTech Inc offers you a range of education & elearning services with the best experience." />
				<link rel="canonical" href="https://vimerseit.com/industries/education-and-elearning" />
			</Helmet>

			<section className='repeat-section banner-section'>
				<div className='dots-shape-animate'>
					<img src='../images/dots-shape-2.png' alt="Shape" />
				</div>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6'>
							<div className='about-content'>
								<div className='section-title text-left'>
									<h1>Education & E-learning</h1>
								</div>
								<p>
									Get powerful education & eLearning solutions and create the best learning experience for trainees, students, and professionals.
								</p>
								<p>
									Digitalization is everywhere, and education is one of the sectors leveraging it in all terms. Revolutionize your training process with the help of Vimerse InfoTech Inc. We provide modern IT solutions for education, e-learning, and corporate training. Our professionals will help you to transform your existing education process into a modern one. With our solutions, you will have a fantastic content delivery experience in the virtual classrooms.
								</p>
								<Link to="/contact" className="default-btn btn-primary mt-5">
									Unlock The Power of Education With Technology!
									<span></span>
								</Link>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='image-radius-design'>
								<img src="../images/industry/education-eLearning.jpg" alt="Education & E-learning" className='w-100' />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-f3f7ff'>
				<div className='container'>
					<div className='section-title'>
						<h2>The Solutions That Makes Learning A Fun</h2>
						<p>We can cater to all your education & training requirements with the latest solutions that your trainees or students will find exciting to access for all their learning needs. Here are the solutions we provide:
						</p>
					</div>
					<div className='row'>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/lms.svg' alt="Learning Management System(LMS)" width="60px" />
								</div>
								<h3>Learning Management System(LMS)</h3>
								<p>Learning management system offers new-age methods to take education to the next level. It offers features like content creation, course creation, tracking progress, and more. You can have it from us with customization.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/virtual-training.svg' alt="Virtual Learning Environment(VLE)" width="60px" />
								</div>
								<h3>Virtual Learning Environment(VLE)</h3>
								<p>VLE, a virtual learning environment, helps students and trainers interact in a virtual environment where knowledge sharing occurs most excitingly. With us, you will have multiple features.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/elearning-mobile-app.svg' alt="eLearning mobile apps" width="60px" />
								</div>
								<h3>eLearning mobile apps</h3>
								<p>E-Learning mobile apps are trending and helping educators to impart education and learning in the best way. It helps trainees to scale up their skills anytime, anywhere. If you need it, then we are here to assist you with that.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/gamification.svg' alt="Gamification and Simulations" width="60px" />
								</div>
								<h3>Gamification and Simulations</h3>
								<p>Gamification and situation solutions will help you to make your students learn effectively. We can provide solutions that make learning more exciting and effective, just like a game.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/content-writer.svg' alt="Content Authoring" width="60px" />
								</div>
								<h3>Content Authoring</h3>
								<p>Content authoring plays a crucial role in any education process. Vimerse InfoTech Inc can help you to develop it by keeping all your needs in mind. Get an innovative content authoring tool from experts.</p>
							</div>
						</div>
						<div className='col-lg-4 col-md-6 d-flex'>
							<div className='single-services-box-item'>
								<div className='icon'>
									<img src='../images/icons/online-training.svg' alt="Online Assessment" width="60px" />
								</div>
								<h3>Online Assessment</h3>
								<p>Quizzes are the best way to make students learn and assess their knowledge. If you want to develop an innovative quiz system, we can provide you with the best resources to help you make it accordingly.</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='cta'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-md-9'>
							<h3>Stay ahead of the curve with the best education practices and modern elearning solutions that offer the best learning experience.</h3>
						</div>
						<div className='col-md-3 text-center'>
							<Link to="/contact" className="default-btn btn-primary">
								Get It Now!
								<span></span>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section className='repeat-section bg-ffffff box-border-bottom-design'>
				<div className='container'>
					<div className='section-title'>
						<h2>Embrace The Technology For Supercharging Education & Learning</h2>
						<p>We are known to develop modern solutions for education and training brands. With us, you will have a fabulous experience with e-learning solutions. The benefits you will have with Vimerse InfoTech Inc:</p>
					</div>
					<div className='row'>
						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Domain Expertise</h3>
								<p>Our expertise allows us to produce the eLearning solutions that students and trainers find better for use. At Vimerse InfoTech Inc, you can hire your own team of experienced professionals for the work.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Latest Technology</h3>
								<p>Whether you need a solution for an online class or want to have AR/VR-based e-learning software, we can assist you with that as we have expertise in using the latest tools & technologies.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>Customization</h3>
								<p>Whatever your need is, we can help you to develop a customized solution. It is one of our top skills to provide personalized solutions to education businesses that helps in better delivery of learning.</p>
							</div>
						</div>

						<div className='col-lg-6 col-md-12 d-flex mb-5'>
							<div className='shadow-box'>
								<h3>One-Stop-Destination</h3>
								<p>Either you run a K-12 academy or provide professional training. We work as a one-stop destination for developing diverse education solutions that create the best effects and provide real-time results.</p>
							</div>
						</div>

					</div>
				</div>
			</section>
		</>
	);
}

export default EducationElearning
